import { mapEnumToOptions } from '../helpers/form-utils';
import {Pipe, PipeTransform} from "@angular/core";

// KL-19 - priežiūros tikslai
export enum SupervisionObjective {
  SelectSamples = 1,
  IssueCertificate = 2,
  Consultation = 3,
  ParticipateOrganizedSupervision= 4,
  Other = 5,
}

export const supervisionObjectiveOptions = mapEnumToOptions<typeof SupervisionObjective>(SupervisionObjective);
export const supervisionObjectiveLabel = (e: SupervisionObjective): string => {
  const str = supervisionObjectiveLabelFull(e);
  return str ? str.substring(0, str.indexOf(' -')) : '';
}
export const supervisionObjectiveLabelFull = (e: SupervisionObjective): string => {
  switch (e) {
    case SupervisionObjective.SelectSamples:
      return 'SM - atrinkti mėginius';
    case SupervisionObjective.IssueCertificate:
      return 'SR - išduoti sertifikatą';
    case SupervisionObjective.Consultation:
      return 'KN - suteikti konsultaciją';
    case SupervisionObjective.ParticipateOrganizedSupervision:
      return 'OP - dalyvauti organizuojamoje priežiūroje';
    case SupervisionObjective.Other:
      return 'KT - kita';
  }
  return '';
}
export const supervisionObjectiveOptions2 = mapEnumToOptions<typeof SupervisionObjective>(SupervisionObjective).map(e => {
  return {
    id: Number(e.key),
    name: supervisionObjectiveLabelFull(SupervisionObjective[e.key as keyof typeof SupervisionObjective]),
    code: '',
  }
});
@Pipe({name: 'supervisionObjectiveLabel'})
export class SupervisionObjectiveLabelPipe implements PipeTransform {
  transform(value: string): any {
    return supervisionObjectiveLabel(SupervisionObjective[value as keyof typeof SupervisionObjective]);
  }
}
