<div class="form-group">
  <label *ngIf="label" for="{{ name }}">
    {{ label }}
  </label>
<div class="input-group">
	<input
    [ngClass]="ngControl.valid ? 'ng-valid' : 'ng-invalid'"
    class="form-control date-field"
    (blur)="inputBlur($event)"
    [ngModel]="dateString | date:inputDatetimeFormat"
    (change)="onInputChange($event)"
    [disabled]="disabled"
    placeholder="{{ inputDatetimeFormat }}"
  />

	  <button
      class="btn btn-secondary"
      [ngbPopover]="calendarContent"
      [disabled]="disabled"
      type="button"
    >
      <fa-icon [icon]="calendarIcon"></fa-icon>
    </button>
</div>

<ng-template #calendarContent>
	<div>
		<div *ngIf="!showTimePickerToggle">
			<ngb-datepicker id="dp" #dp name="datepicker" [ngModel]="datetime"
				(ngModelChange)="onDateChange($event, dp)"></ngb-datepicker>
			<button
        class="btn btn-block btn-outline-secondary"
        [disabled]="!datetime.day"
       
        type="button"
        (click)="toggleDateTimeState($event)"
      >
        <fa-icon [icon]="clockIcon"></fa-icon>
      </button>
		</div>
		<div *ngIf="showTimePickerToggle">
			<button
        class="btn btn-block btn-outline-secondary"
        [ngbPopover]="calendarContent"
        type="button"
        (click)="toggleDateTimeState($event)"
      >
        <fa-icon [icon]="calendarIcon"></fa-icon>
      </button>
			<div class="mt-auto">
				<ngb-timepicker #tp name="timepicker" [ngModel]="datetime" (ngModelChange)="onTimeChange($event)"
					[seconds]="seconds" [hourStep]="hourStep" [minuteStep]="minuteStep" [secondStep]="secondStep">
				</ngb-timepicker>
			</div>
		</div>
	</div>
</ng-template>
</div>