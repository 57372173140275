import { mapEnumToOptions } from '../helpers/form-utils';
import {Pipe, PipeTransform} from "@angular/core";

// KL-17 - mėginių tipas
export enum SampleType {
  Chemical = 1,
  Microbiological = 2,
  Radiological = 3,
}

export const sampleTypeOptions = mapEnumToOptions<typeof SampleType>(SampleType);
export const sampleTypeLabelFull = (e: SampleType): string => {
  switch (e) {
    case SampleType.Chemical:
      return 'ch - cheminis';
    case SampleType.Microbiological:
      return 'm - mikrobiologinis';
    case SampleType.Radiological:
      return 'r - radiologinis';
  }
  return '';
}
export const sampleObjectiveLabel = (e: SampleType): string => {
  const str = sampleTypeLabelFull(e);
  return str ? str.substring(0, str.indexOf(' -')) : '';
}
export const sampleTypeOptions2 = mapEnumToOptions<typeof SampleType>(SampleType).map(e => {
  return {
    id: Number(e.key),
    name: sampleTypeLabelFull(SampleType[e.key as keyof typeof SampleType]),
    code: '',
  }
});
@Pipe({name: 'sampleTypeLabel'})
export class SampleTypeLabelPipe implements PipeTransform {
  transform(value: string): any {
    return sampleTypeLabelFull(SampleType[value as keyof typeof SampleType]);
  }
}
