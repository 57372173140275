import { mapEnumToOptions } from '../helpers/form-utils';
import { Pipe, PipeTransform } from '@angular/core';

export enum SubjectStatus {
  Active = 1,
  Canceled = 2
}

export const subjectStatusOptions = mapEnumToOptions(SubjectStatus);

export const subjectStatusLabel = (e: SubjectStatus): string => {
  switch (e) {
    case SubjectStatus.Active:
      return 'Aktyvus';
    case SubjectStatus.Canceled:
      return 'Panaikintas';
    default:
      return '-';
  }
};
export const subjectStatusOptionsSelect = mapEnumToOptions<typeof SubjectStatus>(SubjectStatus).map(e => {
  return {
    id: Number(e.value),
    name: subjectStatusLabel(e.value),
    code: '',
  };
});

@Pipe({ name: 'subjectStatusLabel' })
export class SubjectStatusPipe implements PipeTransform {
  transform(value: SubjectStatus): string {
    return subjectStatusLabel(value);
  }
}
