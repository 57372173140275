import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmState } from './confirm-state.model';
import { ConfirmOptions } from './confirm-options.model';

@Injectable()
export class ConfirmService {
  constructor(private modalService: NgbModal, private state: ConfirmState) {}

  confirm(options: ConfirmOptions): Promise<any> {
    this.state.options = options;
    this.state.modal = this.modalService.open(this.state.template);
    return this.state.modal.result;
  }
}
