import { mapEnumToOptions } from '../helpers/form-utils';
import {Pipe, PipeTransform} from "@angular/core";

// KL-20 - mėginių ėmimo tikslas
export enum SampleObjective {
  BAAM = 1,
  MaterialResidue = 2,
  FeedSupervision = 3,
  Other = 4,
}

export const sampleObjectiveOptions = mapEnumToOptions<typeof SampleObjective>(SampleObjective);
export const sampleObjectiveLabel = (e: SampleObjective): string => {
  switch (e) {
    case SampleObjective.BAAM:
      return 'BAAM stebėsenos mėginys';
    case SampleObjective.MaterialResidue:
      return 'Medžiagų liekanų stebėsienos mėginys';
    case SampleObjective.FeedSupervision:
      return 'Valstybinės kontrolės pašarų mėginys';
    case SampleObjective.Other:
      return 'Kitas valstybinis mėginys';
  }
  return '';
}
export const sampleObjectiveOptions2 = mapEnumToOptions<typeof SampleObjective>(SampleObjective).map(e => {
  return {
    id: Number(e.key),
    name: sampleObjectiveLabel(SampleObjective[e.key as keyof typeof SampleObjective]),
    code: '',
  }
});
@Pipe({name: 'sampleObjectiveLabel'})
export class SampleObjectiveLabelPipe implements PipeTransform {
  transform(value: string): any {
    return sampleObjectiveLabel(SampleObjective[value as keyof typeof SampleObjective]);
  }
}
