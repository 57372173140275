import { ClsfDropdownItem } from 'src/app/modules/classifiers/clsf-dto';
import { mapEnumToOptions } from '../helpers/form-utils';
import {Pipe, PipeTransform} from "@angular/core";

// KL-23
export enum EventPriority {
  Small = 1,
  Medium = 2,
  High = 3,
  Critical = 4
}

export const eventPriorityOptions = mapEnumToOptions<typeof EventPriority>(EventPriority);
export const eventPriorityLabel = (e: EventPriority): string => {
  switch (e) {
    case EventPriority.Critical:
      return 'labai aukštas';
    case EventPriority.High:
      return 'aukštas';
    case EventPriority.Medium:
      return 'vidutinis';
    case EventPriority.Small:
      return 'žemas';
  }
  return '';
}

export const eventPriorityDropdownItems = mapEnumToOptions<typeof EventPriority>(EventPriority).map(e => {
  return {
    id: Number(e.value),
    name: eventPriorityLabel(EventPriority[ e.key as keyof typeof EventPriority ]),
  } as ClsfDropdownItem;
});

@Pipe({ name: 'eventPriorityLabel' })
export class EventPriorityLabelPipe implements PipeTransform {
  transform(value: EventPriority | string): string {
    return eventPriorityLabel(
      typeof value === 'string'
      ? EventPriority[ value as keyof typeof EventPriority ]
      : value
    );
  }
}