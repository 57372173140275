import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, EventEmitter, forwardRef, Injectable, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { faCalendarDay, faSearch } from '@fortawesome/free-solid-svg-icons';
import { NgbTypeahead, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { catchError, debounceTime, distinctUntilChanged, filter, map, Observable, of, OperatorFunction, Subject, Subscription, switchMap, tap } from 'rxjs';
import { LookupDto, LookupFilterDto } from '../models/common';
import { LookupService } from '../services/lookup.service';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteComponent),
      multi: true,
    },
  ],
})
export class AutocompleteComponent implements ControlValueAccessor, OnInit, OnDestroy {

  @Input()
  lookupId: string = 'lookup-id';

  @Input()
  public lookupApiUrl: string = '';

  @Input()
  public placeholder: string = 'Įveskite ieškomo teksto fragmentą';

  @Input()
  public label: string = 'Paieška';

  @Input()
  selectedLookup: LookupDto | undefined;

  @Input()
  requiredField: boolean = false;

  @Input()
  referenceId: number | null = null;

  @Input()
  selectKey: 'id' | 'code' = 'id';

  @Input()
  isDisabled: boolean = false;

  @Input()
  isInvalid: boolean = false;

  @Output() onSearchClearedOrChanged: EventEmitter<any> = new EventEmitter();

  private onTouched!: Function;

  private onChanged!: Function;

  @Input()
  selectedClassifiers: number[] = [];

  model: any;
  searching = false;
  searchFailed = false;

  searchIcon = faSearch;

  @ViewChild('instance', {static: true}) instance: NgbTypeahead | undefined;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  inputFormatter = (lookup: LookupDto) => lookup.name;

  resultFormatter = (lookup: LookupDto) => lookup.name;

  private lookupSub: Subscription | undefined;

  constructor(private _service: LookupService) {}

  writeValue(value: number): void {
    if(value) {
      this.lookupSub = this._service.getLookupById(this.lookupApiUrl, value).subscribe(r => {
        this.model = {
          id: value,
          name: r.name,
          code: r.code
        };
      });
    }
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngOnInit(): void {
    if (this.lookupApiUrl === '') {
      throw Error('Lookup api url param is empty')
    }
  }

  ngOnDestroy(): void {
      this.lookupSub?.unsubscribe();
  }

  search: OperatorFunction<string, readonly LookupDto[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      filter(term => term.length >= 2),
      tap(() => this.searching = true),
      switchMap(term => {
        return this._service.getFilteredLookupWithReferenceData(this.lookupApiUrl, this.formatFilterQuery(term)).pipe(
          map((result) => {
            const filteredResult = result.filter(block => !this.selectedClassifiers.includes(block.id));
            return filteredResult;
          }),
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          }))
      }
      ),
      tap(() => this.searching = false)
    );

  handleSelect(event: NgbTypeaheadSelectItemEvent) {
    this.onTouched();
    this.onSearchClearedOrChanged.emit();
    if(this.selectKey == 'id')
      this.onChanged?.(event.item.id);
    else
      this.onChanged?.(event.item.code);
  }

  handleSearchClear(){
    this.onSearchClearedOrChanged.emit();
    this.onChanged(null);
  }

  formatFilterQuery(term: string) {
    const filter: LookupFilterDto = {
      name: term,
      referenceId: this.referenceId
    }
    return filter;
  }

  clearInput(): void {
    this.model = null;
  }
}
