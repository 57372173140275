import { Component, computed, forwardRef, input, Input, OnInit, signal } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DropdownMultiSelectItem } from './models/dropdown-multi-select-item.model';
import { getItemList } from '../helpers/object-list';;
import { ClsfBaseResponseDto } from 'src/app/modules/classifiers/clsf-dto';

@Component({
  selector: 'app-dropdown-multi-select',
  templateUrl: './dropdown-multi-select.component.html',
  styleUrls: ['./dropdown-multi-select.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownMultiSelectComponent),
      multi: true,
    },
  ],
})
export class DropdownMultiSelectComponent implements ControlValueAccessor, OnInit
{
  @Input()
  isDisabled: boolean = false;

  name = input<string>('dropdownmultiselect')

  items = input<DropdownMultiSelectItem[]>([]);

  referenceId = input<number | null>(null);

  dropdownPlaceholder = input<string>('Pasirinkite');

  selectedKey = signal<number | null>(null);

  label = input<string>('dropdown multi select')

  keys = signal<number[]>([])

  selectedSignal = signal<DropdownMultiSelectItem[]>([]);

  listData = computed(() => {
    const mappedItemsReference = this.selectedSignal().map(item => ({
      id: item.key!,
      name: item.displayValue! || '',
      reference: item.reference || {} as ClsfBaseResponseDto
    }));
    return getItemList(mappedItemsReference);
  });

  private onTouched!: Function;

  private onChanged!: Function;

  constructor() {} 

  ngOnInit() {
    this.selectedSignal.set(this.selected);
  }

  get selected() {
    return this.items().filter((e) => e.selected === true);
  }

  get notSelected() {
    if(this.referenceId()){
      return this.items().filter((e) => !e.selected && e.reference?.id === this.referenceId());
    }
    return this.items().filter((e) => e.selected == false);
  }

  writeValue(value: number[]): void {
    this.keys.set(value);
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  select(event: Event) {
    if(this.isDisabled){
      return;
    }
    event.preventDefault();
    this.items().forEach((e) => {
      if (e.key == this.selectedKey()) {
        e.selected = true;
      }
    });
    this.selectedKey.set(null);
    this.selectKeys();
  }

  deselect(event: Event, key?: number) {
    if(this.isDisabled || !key){
      return;
    }
    
    event.preventDefault();
    this.items().forEach((e) => {
      if (e.key == key) {
        e.selected = false;
      }
    });
    this.selectKeys();
  }

  selectKeys() {
    this.onTouched?.();
    this.onChanged?.(
      this.items().filter((p) => p.selected === true).map((e) => e.key)
    );
    this.selectedSignal.update(() => this.selected);
  }

  hasReference(){
    return this.items()[0]?.reference !== undefined;
  }
}
