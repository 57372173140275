import { Injectable, inject } from '@angular/core';
import { AuthService, User, UserInfoDto, UserInfoOrganizationDto } from 'src/app/services/auth.service';
import { userPermissions, UserPermissions } from './models/user-permissions';

@Injectable({
  providedIn: 'root'
})
export class UserAuthorizationService {
  private readonly authService = inject(AuthService);

  get user(): User /*| null*/ {
    return this.authService.userState!;
  }
  get userInfo(): UserInfoDto /*| null*/ {
    return this.authService.userState!.userInfo;
  }

  get activeOrganization(): UserInfoOrganizationDto | undefined {
    return this.userInfo.clsfOrganizations.find(x => x.isSelected);
  }

  hasPermissionAccess(permissions: UserPermissions[]) {
    return this.authService.hasPermission(permissions);
  }

  /** Peržiūrėti ūkio subjektų sarašą  */
  get canViewSubjectList(): boolean {
    return this.authService.hasPermission([userPermissions.VIEW_SUBJECT_LIST]);
  }

  /** Peržiūrėti ūkio subjektus */
  get canViewSubjectDetails(): boolean {
    return this.authService.hasPermission([userPermissions.VIEW_SUBJECT]);
  }

  /** Tvarkyti subjektus */
  get canManageSubjects(): boolean {
    return this.authService.hasPermission([userPermissions.CREATE_SUBJECT, userPermissions.UPDATE_SUBJECT]);
  }

  get canCreateSubject(): boolean {
    return this.authService.hasPermission([userPermissions.CREATE_SUBJECT]);
  }

  /** Redaguoti ūkio subjektus */
  get canUpdateSubjects(): boolean {
    return this.authService.hasPermission([userPermissions.UPDATE_SUBJECT]);
  }

  /** Trinti ūkio subjektus */
  get canDeleteSubjects(): boolean {
    return this.authService.hasPermission([userPermissions.DELETE_SUBJECT]);
  }

  /** Peržiūrėti veiklaviečių sarašą */
  get canViewControlObjectList(): boolean {
    return this.authService.hasPermission([userPermissions.VIEW_CONTROL_OBJECT_LIST]);
  }

  /** Peržiūrėti veiklavietes */
  get canViewControlObjectDetails(): boolean {
    return this.authService.hasPermission([userPermissions.VIEW_CONTROL_OBJECT]);
  }

  /** Sukurti naują veiklavietę */
  get canCreateControlObject(): boolean {
    return this.authService.hasPermission([userPermissions.CREATE_CONTROL_OBJECT]);
  }

  /** Redaguoti naują veiklavietę */
  get canEditControlObject(): boolean {
    return this.authService.hasPermission([userPermissions.UPDATE_CONTROL_OBJECT]);
  }

  /** Tvarkyti Kontrolės objektus */
  get canManageControlObjects(): boolean {
    return this.authService.hasPermission([userPermissions.CREATE_CONTROL_OBJECT, userPermissions.UPDATE_CONTROL_OBJECT]);
  }

  /** Trinti veiklavietes */
  get canDeleteControlObjects(): boolean {
    return this.authService.hasPermission([userPermissions.DELETE_CONTROL_OBJECT]);
  } 

  /** Įvesti rizikos vertinimą */
  get canAddRiskEvaluation(): boolean {
    return this.authService.hasPermission([userPermissions.ADD_RISK_EVALUATION]);
  }

  /** Tvirtinti rizikos vertinimą */
  get canApproveRiskEvaluation(): boolean {
    return this.authService.hasPermission([userPermissions.APPROVE_RISK_EVALUATION]);
  }

  /** Peržiūrėti planą */
  get canViewPlan(): boolean {
    return this.authService.hasPermission([userPermissions.VIEW_PLAN]);
  }

  /** Tvirtinti planą */
  get canApprovePlan(): boolean {
    return this.authService.hasPermission([userPermissions.APPROVE_PLAN]);
  }

/*  /!** Tvirtinti metų planą *!/ // TODO: turbut sito nebereikes.
  get canApproveAnnualPlan(): boolean {
    return this.authService.hasPermission(['APPROVE_ANNUAL_PLAN']);
  }*/

  /** Planuoti įvykius */
  get canPlanEvents(): boolean {
    return this.authService.hasPermission([userPermissions.PLAN_EVENT]);
  }

  get canManageEvents(): boolean {
    return this.authService.hasPermission([userPermissions.CREATE_EVENT, userPermissions.UPDATE_EVENT]);
  }

  get canViewEventList(): boolean {
    return this.authService.hasPermission([userPermissions.VIEW_EVENT_LIST]);
  }

  get canViewEvent(): boolean {
    return this.authService.hasPermission([userPermissions.VIEW_EVENT]);
  }

  get canCreateEvent(): boolean {
    return this.authService.hasPermission([userPermissions.CREATE_EVENT]);
  }

  get canEditEvent(): boolean {
    return this.authService.hasPermission([userPermissions.UPDATE_EVENT]);
  }

  get canDeleteEvent(): boolean {
    return this.authService.hasPermission([userPermissions.DELETE_EVENT]);
  }

  /** Importuoti įvykius (excel failas) */
  get canImportPlanFile(): boolean {
    return this.authService.hasPermission([userPermissions.IMPORT_PLAN_FILE]);
  }

  get canViewApprovedPlanHistory(): boolean {
    return this.authService.hasPermission([userPermissions.VIEW_PLAN_HISTORY]);
  }

  /** Inicijuoti plano pergeneravimą */
  get canInitiatePlanGeneration(): boolean {
    return this.authService.hasPermission([userPermissions.INITIATE_PLAN_GENERATION]);
  }

  get canRegeneratePlan(): boolean {
    return this.authService.hasPermission([userPermissions.REGENERATE_PLAN]);
  }

  /** Peržiūrėti pavedimų sarašą */
  get canViewOrderList(): boolean {
    return this.authService.hasPermission([userPermissions.VIEW_ORDER_LIST]);
  }

  get canViewRelatedOrders(): boolean {
    return this.authService.hasPermission([userPermissions.VIEW_RELATED_CONTROL_OBJECT_ORDER]);
  }

  get canCreateOrderForAnyOrganization(): boolean {
    return this.authService.hasPermission([userPermissions.CREATE_ORDER_FOR_ANY_ORGANIZATION]);
  }

  get canCreateOrderForUserOrganization(): boolean {
    return this.authService.hasPermission([userPermissions.CREATE_ORDER]);
  }

  /** Peržiūrėti pavedimus */
  get canViewOrderDetails(): boolean {
    return this.authService.hasPermission([userPermissions.VIEW_ORDER]);
  }

  /** Tvarkyti pavedimus */
  get canManageOrders(): boolean {
    return this.authService.hasPermission([userPermissions.UPDATE_ORDER, userPermissions.CREATE_ORDER, userPermissions.CREATE_ORDER_FOR_ANY_ORGANIZATION]);
  }

  /** Redaguoti pavedimus */
  get canEditOrders(): boolean {
    return this.authService.hasPermission([userPermissions.UPDATE_ORDER]);
  }

  /** Trinti pavedimus */
  get canDeleteOrders(): boolean {
    return this.authService.hasPermission([userPermissions.DELETE_ORDER]);
  }

  /** Gali matyti menu punkta 'mano pavedimai' */
  get canViewMyOrders(): boolean {
    return this.authService.hasPermission([userPermissions.VIEW_MY_ORDER_LIST]);
  }

  /** Gali vykdyti pavedimus */
  get canManageInspections(): boolean {
    return this.authService.hasPermission([userPermissions.VIEW_INSPECTION, userPermissions.UPDATE_INSPECTION]);
  }

  get canFinishInspections(): boolean {
    return this.authService.hasPermission([userPermissions.FINISH_ASSIGNED_INSPECTION]);
  }

  /** Gali pasirašyti pavedimus */
  get canSignInspections(): boolean {
    return this.authService.hasPermission([userPermissions.SIGN_ORDER]);
  }

  /** Vykdyti patikrinimą */
  get canStartInspection(): boolean {
    return this.authService.hasPermission([userPermissions.START_INSPECTION]);
  }

  /** Peržiūrėti patikrinimų duomenis */
  get canViewInspectionData(): boolean {
    return this.authService.hasPermission([userPermissions.VIEW_INSPECTION]);
  }

  /** Perziureti pranesimus */
  get canViewComplaints(): boolean {
    return this.authService.hasPermission([userPermissions.VIEW_COMPLAINT]);
  }

  /** Tvarkyti pranešimus */
  get canManageComplaints(): boolean {
    return this.authService.hasPermission([userPermissions.MANAGE_COMPLAINTS]);
  }

  /** Tvirtinti pranešimus */
  get canApproveComplaints(): boolean {
    return this.authService.hasPermission([userPermissions.APPROVE_COMPLAINT]);
  }

  /** Vykdyti priežiūrą */
  get canStartSupervision(): boolean {
    return this.authService.hasPermission([userPermissions.START_SUPERVISION]);
  }

  /** Peržiūrėti priežiūros rezultatus */
  get canViewSupervisionResults(): boolean {
    return this.authService.hasPermission([userPermissions.VIEW_SUPERVISION_RESULT]);
  }

  get canSelectAnyOrganizationForEvent(): boolean {
    return this.authService.hasPermission([userPermissions.CREATE_EVENT_ANY_ORGANIZATION]);
  }

  /** Administruoti sistemą */
  get canOpenAdmin(): boolean {
    return this.authService.hasPermission([userPermissions.SYSTEM_ADMINISTRATION]);
  }

  /** Gali matyti menu punkta 'admin' */
  get canViewAdmin(): boolean {
    return this.authService.hasPermission([userPermissions.SYSTEM_ADMINISTRATION]);
  }

  /** Gali pridėti naują klasifikatoriaus įrašą' */
  get canCreateClassification(): boolean {
    return this.authService.hasPermission([userPermissions.CREATE_CLASSIFIER])
  }

  get canUpdateClassifierEndDate(): boolean {
    return this.authService.hasPermission([userPermissions.UPDATE_CLASSIFIER_DATE])
  }

  /** Gali redaguoti klasifikatoriaus įrašą' */
  get canEditClassification(): boolean {
    return this.authService.hasPermission([userPermissions.UPDATE_CLASSIFIER])
  }

  /** Gali ištrinti klasifikatoriaus įrašą' */
  get canDeleteClassification(): boolean {
    return this.authService.hasPermission([userPermissions.DELETE_CLASSIFIER])
  }

  get canViewRelatedCompletedOrder(): boolean {
    return this.authService.hasPermission([userPermissions.INITIATE_PLAN_GENERATION]);
  }

  get canViewClassifiersList(): boolean {
    return this.authService.hasPermission([userPermissions.VIEW_CLASSIFIER_LIST]);
  }
}
