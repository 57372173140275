import { Component, Input, OnInit } from '@angular/core';
import { GeneralDataDisplayItem } from './models/general-data-display-item.model';

@Component({
  selector: 'app-general-data-display',
  templateUrl: './general-data-display.component.html',
  styleUrls: ['./general-data-display.component.less'],
})
export class GeneralDataDisplayComponent implements OnInit {
  @Input()
  items: GeneralDataDisplayItem[] = [];

  @Input()
  title: string = '';

  constructor() {}

  ngOnInit(): void {}
}
