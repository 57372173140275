import { mapEnumToOptions } from '../helpers/form-utils';
import { Pipe, PipeTransform } from '@angular/core';

export enum ControlObjectStatus {
  Active = 1,
  Paused = 2,
  Canceled = 3
}

export const controlObjectStatusOptions = mapEnumToOptions(ControlObjectStatus);

export const controlObjectStatusLabel = (e: ControlObjectStatus): string => {
  switch (e) {
    case ControlObjectStatus.Active:
      return 'Aktyvus';
    case ControlObjectStatus.Paused:
      return 'Sustabdytas';
    case ControlObjectStatus.Canceled:
      return 'Panaikintas';
    default:
      return '-';
  }
};
export const controlObjectStatusOptionsSelect = mapEnumToOptions<typeof ControlObjectStatus>(ControlObjectStatus).map(e => {
  return {
    id: Number(e.value),
    name: controlObjectStatusLabel(e.value),
    code: '',
  };
});

@Pipe({ name: 'controlObjectStatusLabel' })
export class ControlObjectStatusPipe implements PipeTransform {
  transform(value: ControlObjectStatus): string {
    return controlObjectStatusLabel(value);
  }
}
