<div class="user header-block">
    <ul ngbNav #nav="ngbNav" class="nav">
      <li ngbDropdown class="nav-item">
        <a href (click)="(false)" class="nav-link" ngbDropdownToggle
          ><span class="name" *ngIf="userState"
            >{{ userState.firstName }}
            {{ userState.lastName }}</span
          >
          </a>
        <div ngbDropdownMenu>
          <button ngbDropdownItem (click)="signOut()">Atsijungti</button>
        </div>
      </li>
    </ul>
    <!--TODO sutvarkyti organizacijas-->
    <span class="subtitle" *ngIf="userState">{{ userState.department }}</span>
</div>
