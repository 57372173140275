import { NgModule } from '@angular/core';
import { InspectionKindLabelPipe } from './inspection-kind.enum';
import { EventPriorityLabelPipe } from './event-priority.enum';
import { InspectionTypeLabelPipe } from './inspection-type.enum';
import { InspectionObjectiveLabelPipe } from './inspection-objective.enum';
import { SupervisionObjectiveLabelPipe } from './maintenance-type.enum';
import { SampleObjectiveLabelPipe } from './sample-purpose.enum';
import { SampleTypeLabelPipe } from './sample-type.enum';
import { SupportTypeLabelPipe } from './subsidy-type.enum';
import { DateMonthLabelPipe } from './date-month.enum';
import { RiskGroupLabelPipe } from './risk-group.enum';
import { FrequencyOfInspectionLabelPipe } from './frequency-of-inspection.enum';
import { OrderStatusPipe } from './order-status.enum';
import { SubjectLegalFormPipe } from './subject-legal-form';
import { ControlObjectStatusPipe } from './control-object-status.enum';
import { SubjectStatusPipe } from './subject-status.enum';
import { EvaluationRulePipe } from './evalution-rule.enum';
import { ActivityStatusPipe } from './activity-status.enum';
import { SubjectTypePipe } from './subject-type.enum';
import { ControlObjectTypePipe } from './control-object-type.enum';

@NgModule({
  imports: [],
  declarations: [
    InspectionKindLabelPipe,
    EventPriorityLabelPipe,
    InspectionTypeLabelPipe,
    InspectionObjectiveLabelPipe,
    SupervisionObjectiveLabelPipe,
    SampleObjectiveLabelPipe,
    SampleTypeLabelPipe,
    SupportTypeLabelPipe,
    DateMonthLabelPipe,
    RiskGroupLabelPipe,
    FrequencyOfInspectionLabelPipe,
    OrderStatusPipe,
    SubjectLegalFormPipe,
    ControlObjectStatusPipe,
    EvaluationRulePipe,
    SubjectStatusPipe,
    ActivityStatusPipe,
    SubjectTypePipe,
    ControlObjectTypePipe
  ],
  exports: [
    InspectionKindLabelPipe,
    EventPriorityLabelPipe,
    InspectionTypeLabelPipe,
    InspectionObjectiveLabelPipe,
    SupervisionObjectiveLabelPipe,
    SampleObjectiveLabelPipe,
    SampleTypeLabelPipe,
    SupportTypeLabelPipe,
    DateMonthLabelPipe,
    RiskGroupLabelPipe,
    FrequencyOfInspectionLabelPipe,
    OrderStatusPipe,
    SubjectLegalFormPipe,
    ControlObjectStatusPipe,
    EvaluationRulePipe,
    SubjectStatusPipe,
    ActivityStatusPipe,
    SubjectTypePipe,
    ControlObjectTypePipe
  ],
})
export class SharedEnumsModule {
}
