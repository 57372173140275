import { mapEnumToOptions } from '../helpers/form-utils';
import {Pipe, PipeTransform} from "@angular/core";

// KL-18 - patikrinimo tikslai
export enum InspectionObjective {
  FeedControl = 1,
  AnimalByProductsControl = 2,
  VeterinaryMedicineControl = 3,
  BiocidesControl= 4,
  AnimalHealthControl = 5,
  AnimalWelfareControl = 6,
  AnimalLabelingControl = 7,
  AdvertisingControl = 8,
  RvasvtSystemAndOrHgpAudit = 9,
  FarmAnimalBreedingControl = 10,
  Other = 11,
}

export const inspectionObjectiveOptions = mapEnumToOptions<typeof InspectionObjective>(InspectionObjective);
export const inspectionObjectiveLabel = (e: InspectionObjective): string => {
  const str = inspectionObjectiveLabelFull(e);
  return str ? str.substring(0, str.indexOf(' -')) : '';
}
export const inspectionObjectiveLabelFull = (e: InspectionObjective): string => {
  switch (e) {
    case InspectionObjective.FeedControl:
      return 'P - pašarų saugos, kokybės, ženklinimo kontrolė';
    case InspectionObjective.AnimalByProductsControl:
      return 'ŠGP - šalutinių gyvūninių produktų saugos, kokoybė, ženklinimo kontrolė';
    case InspectionObjective.VeterinaryMedicineControl:
      return 'F - veterinarinių vaistų ir veterinarinių priemonių tvarkymo kontrolė';
    case InspectionObjective.BiocidesControl:
      return 'B - biocidinių produktų kontrolė';
    case InspectionObjective.AnimalHealthControl:
      return 'S - gyvūnų sveikatos kontrolė';
    case InspectionObjective.AnimalWelfareControl:
      return 'G - gyvūnų gerovės kontrolė';
    case InspectionObjective.AnimalLabelingControl:
      return 'Ž - gyvūnų ženklinimo kontrolė';
    case InspectionObjective.AdvertisingControl:
      return 'R - reklamos (informacijos apie prekes, įskaitant kainų nurodymą) kontrolė';
    case InspectionObjective.RvasvtSystemAndOrHgpAudit:
      return 'A - RVASVT sistemos ir (ar) GHP auditas';
    case InspectionObjective.FarmAnimalBreedingControl:
      return 'V - ūkinių gyvūnų veislininkystės kontrolė';
    case InspectionObjective.Other:
      return 'K - kita';
  }
  return '';
}
export const inspectionObjectiveOptions2 = mapEnumToOptions<typeof InspectionObjective>(InspectionObjective).map(e => {
  return {
    id: Number(e.key),
    name: inspectionObjectiveLabelFull(InspectionObjective[e.key as keyof typeof InspectionObjective]),
    code: '',
  }
});
@Pipe({name: 'inspectionObjectiveLabel'})
export class InspectionObjectiveLabelPipe implements PipeTransform {
  transform(value: string): any {
    return inspectionObjectiveLabel(InspectionObjective[value as keyof typeof InspectionObjective]);
  }
}
