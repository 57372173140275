import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LookupDto } from '../models/common';
import { RestBaseService } from './rest-base.service';

@Injectable({
  providedIn: 'root',
})
export class LookupService extends RestBaseService {
  constructor(http: HttpClient) {
    super(http);
  }

  public getFilteredLookupData(
    lookupApiUrl: string,
    filter: string | null = null
  ): Observable<LookupDto[]> {
    return this.GetFilteredAsync<LookupDto[]>({filter}, lookupApiUrl);
  }

  public getLookupById(
    lookupApiUrl: string,
    id: number
  ): Observable<LookupDto> {
    return this.GetByIdAsync<LookupDto>(id, lookupApiUrl);
  }

  public getFilteredLookupWithReferenceData(
    lookupApiUrl: string,
    filter: any
  ): Observable<LookupDto[]> {
    return this.GetFilteredAsync<LookupDto[]>(filter, lookupApiUrl);
  }
}
