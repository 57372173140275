import { mapEnumToOptions } from '../helpers/form-utils';
import { Pipe, PipeTransform } from '@angular/core';

export enum ControlObjectType {
  ActivityPlace = 1,
  ControlPoint = 2,
}

export const controlObjectTypeOptions = mapEnumToOptions(ControlObjectType);

export const controlObjectTypeLabel = (e: ControlObjectType): string => {
  switch (e) {
    case ControlObjectType.ActivityPlace:
      return 'Veiklavietė';
    case ControlObjectType.ControlPoint:
      return 'Kontrolės vieta';
    default:
      return '-';
  }
};
export const controlObjectTypeOptionsSelect = mapEnumToOptions<typeof ControlObjectType>(ControlObjectType).map(e => {
  return {
    id: Number(e.value),
    name: controlObjectTypeLabel(e.value),
    code: '',
  };
});

@Pipe({ name: 'controlObjectTypeLabel' })
export class ControlObjectTypePipe implements PipeTransform {
  transform(value: ControlObjectType): string {
    return controlObjectTypeLabel(value);
  }
}
