import { mapEnumToOptions } from '../helpers/form-utils';
import {Pipe, PipeTransform} from "@angular/core";

// KL-21 - ūkio paramos tipas
export enum SupportType {
  Kompleksine = 1,
  Susietoji = 2,
}

export const supportTypeOptions = mapEnumToOptions<typeof SupportType>(SupportType);
export const supportTypeLabelFull = (e: SupportType): string => {
  switch (e) {
    case SupportType.Kompleksine:
      return 'K - Kompleksinė';
    case SupportType.Susietoji:
      return 'S - Susietoji';
    default:
      return '';
  }
}
export const sampleObjectiveLabel = (e: SupportType): string => {
  const str = supportTypeLabelFull(e);
  return str ? str.substring(0, str.indexOf(' -')) : '';
}
export const supportTypeDropdownItems = mapEnumToOptions<typeof SupportType>(SupportType).map(e => {
  return {
    id: Number(e.value),
    name: supportTypeLabelFull(SupportType[e.key as keyof typeof SupportType]),
    code: '',
  }
});
@Pipe({name: 'supportTypeLabel'})
export class SupportTypeLabelPipe implements PipeTransform {
  transform(value: string): any {
    return supportTypeLabelFull(SupportType[value as keyof typeof SupportType]);
  }
}
