import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentWrapperComponent } from './content-wrapper/content-wrapper.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderWrapperComponent } from './header-wrapper/header-wrapper.component';
import { PageLayoutComponent } from './page-layout/page-layout.component';
import { PrimaryMenuHeaderComponent } from './primary-menu-header/primary-menu-header.component';
import { SecondaryMenuComponent } from './secondary-menu/secondary-menu.component';
import { RouterModule } from '@angular/router';
import { SecondaryMenuWithCancelComponent } from './secondary-menu-with-cancel/secondary-menu-with-cancel.component';
import { ThirdTabsMenuComponent } from './third-tabs-menu/third-tabs-menu.component';
import { SharedModule } from '../shared/shared.module';
import { EnvironmentComponent } from './environment/environment.component';
import { UserInfoComponent } from './user-info/user-info.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    ContentWrapperComponent,
    FooterComponent,
    HeaderWrapperComponent,
    PageLayoutComponent,
    PrimaryMenuHeaderComponent,
    SecondaryMenuComponent,
    SecondaryMenuWithCancelComponent,
    ThirdTabsMenuComponent,
    EnvironmentComponent,
    UserInfoComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    NgbModule
  ],
  exports: [
    ContentWrapperComponent,
    FooterComponent,
    HeaderWrapperComponent,
    PageLayoutComponent,
    PrimaryMenuHeaderComponent,
    SecondaryMenuComponent,
    SecondaryMenuWithCancelComponent,
    ThirdTabsMenuComponent
  ],
  providers: [

  ],
})
export class LayoutModule {}
