<div class="form-group form-group-padding" [disableForm]="isDisabled">
  <label for="{{ name() }}_select">{{ label() }}</label>
  <div class="select-holder">
    <select
    class="dropdown-display"
      name="{{ name() }}_select"
      id="{{ name() }}-select"
      [(ngModel)]="selectedKey"
    >
      <option [ngValue]="null">{{ dropdownPlaceholder() }}</option>
      <option [ngValue]="option.key" *ngFor="let option of this.notSelected" title="{{option.displayValue}}">
        {{ option.displayValue | slice:0:80 }}{{ option.displayValue!.length > 80 ? '...' : ''}}
      </option>
    </select>
    <a class="btn-add" (click)="select($event)">+</a>
  </div>
</div>
<div class="form-group" [disableForm]="isDisabled">
  @if(!hasReference()) {
    <div class="checkbox checkbox-block">
      @for(item of selected; let i = $index; track i) {
        <div class="checkbox-holder checkbox-holder-minus checked">
          <label>
            {{ item.displayValue }}
            <a class="checkboxbtn" (click)="deselect($event, item.key)"></a>
          </label>
        </div>
      }
    </div>
  } @else {
    <div class="checkbox checkbox-block">
      <div
        *ngFor="let item of listData()"
      >
        <p class="text-md fw-bold">
          {{ item.item }}
        </p>
        <div
        class="checkbox-holder checkbox-holder-minus checked"
        *ngFor="let referencedItem of item.referencedItems"
      >
        <label class="fw-normal">
          {{ referencedItem.name }}
          <a class="checkboxbtn" (click)="deselect($event, referencedItem.id)"></a>
        </label>
      </div>
      </div>
    </div>
  }
</div>
