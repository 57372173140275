import { Component } from '@angular/core';
import { AuthService, User } from 'src/app/services/auth.service';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.less']
})
export class UserInfoComponent {
  userState: User | null | undefined = this.authService.userState;

  constructor(public authService: AuthService) { }

  signOut(): void{
    this.authService.logoutUser();
  }
}