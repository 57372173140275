<div class="no-padding">
  @if (type() === "images"){
    <label class="input-group-btn">
      <span class="btn btn-gray upload-button" [ngClass]="isDisabled ? 'disabled' : ''">
          <fa-icon [icon]="cameraIcon"></fa-icon><input
          #fileSelectElement
          type="file"
          style="display: none"
          multiple
        />
      </span>
    </label>
  }
  @if (type() === "files") {
    <label class="form-group-files-label"> Pridedami failai: </label>
  }
    <div class="form-group-files">
      <div [ngClass]="type() === 'files' ? 'files' : 'images'">
        <div *ngFor="let file of files; let i = index">
          <div *ngIf="!file.attachedFile" [ngClass]="type() === 'files' ? 'file' : 'image'">
            @if (type() === "files") {
              <a
              href="#"
              *ngIf="file.fileDescriptorId && file.fileName"
              (click)="download($event, file.fileDescriptorId, file.fileName)"
              >{{ file.fileName }}</a>
              <a *ngIf="isDisabled === false" href="#" class="remove" (click)="removeFileFromList($event, i)">-</a>
            }
            @else {
              <input type="text" [ngClass]="file ? 'file-is-selected' : ''" class="upload-info-field form-control" readonly [value]="file ? file.fileName : ''">
              <a *ngIf="!isDisabled" (click)="removeFileFromList($event, i)" class="btn btn-gray reset-file-input-button" type="button" id="button-addon2"><fa-icon [icon]="deleteIcon"></fa-icon></a>
              <a *ngIf="file.fileDescriptorId && file.fileName" (click)="download($event, file.fileDescriptorId, file.fileName)" class="btn btn-gray reset-file-input-button" type="button" id="button-addon2"><fa-icon [icon]="downloadIcon"></fa-icon></a>
            }
          </div>   
        </div>
      </div>
      @if(type() === "files") {
      <div class="action">
        <label class="btn btn-gray">
          Įkelti failą/us<input
            #fileSelectElement
            type="file"
            style="display: none"
            multiple
          />
        </label>
      </div>
    }
    </div>
</div>

