export const userPermissions = {
  'SYSTEM_ADMINISTRATION': 'SYSTEM_ADMINISTRATION',
  'CREATE_SUBJECT': 'CREATE_SUBJECT',
  'UPDATE_SUBJECT': 'UPDATE_SUBJECT',
  'DELETE_SUBJECT': 'DELETE_SUBJECT',
  'VIEW_SUBJECT': 'VIEW_SUBJECT',
  'VIEW_SUBJECT_LIST': 'VIEW_SUBJECT_LIST',
  'VIEW_CONTROL_OBJECT': 'VIEW_CONTROL_OBJECT',
  'CREATE_CONTROL_OBJECT': 'CREATE_CONTROL_OBJECT',
  'UPDATE_CONTROL_OBJECT': 'UPDATE_CONTROL_OBJECT',
  'DELETE_CONTROL_OBJECT': 'DELETE_CONTROL_OBJECT',
  'VIEW_CONTROL_OBJECT_LIST': 'VIEW_CONTROL_OBJECT_LIST',
  'VIEW_ORDER_LIST': 'VIEW_ORDER_LIST',
  'VIEW_ORDER': 'VIEW_ORDER',
  'VIEW_RELATED_CONTROL_OBJECT_ORDER': 'VIEW_RELATED_CONTROL_OBJECT_ORDER',
  'VIEW_RELATED_COMPLETED_ORDER': 'VIEW_RELATED_COMPLETED_ORDER',
  'VIEW_RELATED_ONGOING_ORDER': 'VIEW_RELATED_ONGOING_ORDER',
  'CREATE_ORDER': 'CREATE_ORDER',
  'UPDATE_ORDER': 'UPDATE_ORDER',
  'DELETE_ORDER': 'DELETE_ORDER',
  'SIGN_ORDER': 'SIGN_ORDER',
  'CREATE_ORDER_FOR_ANY_ORGANIZATION': 'CREATE_ORDER_FOR_ANY_ORGANIZATION',
  'VIEW_RELATED_ORDER_ANY_ORGANIZATION': 'VIEW_RELATED_ORDER_ANY_ORGANIZATION',
  'OFFICER_INSPECTOR_PERMISSION': 'OFFICER_INSPECTOR_PERMISSION',
  'VIEW_PLAN': 'VIEW_PLAN',
  'VIEW_ALL_PLANS': 'VIEW_ALL_PLANS',
  'VIEW_ORGANIZATION_PLANS': 'VIEW_ORGANIZATION_PLANS',
  'APPROVE_PLAN': 'APPROVE_PLAN',
  'APPROVE_ANNUAL_PLAN': 'APPROVE_ANNUAL_PLAN',
  'REGENERATE_PLAN': 'REGENERATE_PLAN',
  'IMPORT_PLAN_FILE': 'IMPORT_PLAN_FILE',
  'VIEW_PLAN_HISTORY': 'VIEW_PLAN_HISTORY',
  'INITIATE_PLAN_GENERATION': 'INITIATE_PLAN_GENERATION',
  'VIEW_EVENT': 'VIEW_EVENT',
  'VIEW_EVENT_LIST': 'VIEW_EVENT_LIST',
  'CREATE_EVENT': 'CREATE_EVENT',
  'UPDATE_EVENT': 'UPDATE_EVENT',
  'DELETE_EVENT': 'DELETE_EVENT',
  'PLAN_EVENT': 'PLAN_EVENT',
  'CREATE_EVENT_ANY_ORGANIZATION': 'CREATE_EVENT_ANY_ORGANIZATION',
  'CREATE_CLASSIFIER': 'CREATE_CLASSIFIER',
  'UPDATE_CLASSIFIER': 'UPDATE_CLASSIFIER',
  'DELETE_CLASSIFIER': 'DELETE_CLASSIFIER',
  'UPDATE_CLASSIFIER_DATE': 'UPDATE_CLASSIFIER_DATE',
  'START_INSPECTION': 'START_INSPECTION',
  'START_ASSIGNED_INSPECTION': 'START_ASSIGNED_INSPECTION',
  'FINISH_ASSIGNED_INSPECTION': 'FINISH_ASSIGNED_INSPECTION',
  'VIEW_UNASSIGNED_INSPECTIONS': 'VIEW_UNASSIGNED_INSPECTIONS',
  'VIEW_INSPECTION': 'VIEW_INSPECTION',
  'VIEW_ANY_INSPECTION': 'VIEW_ANY_INSPECTION',
  'COMPLETE_INSPECTION': 'COMPLETE_INSPECTION',
  'UPDATE_INSPECTION': 'UPDATE_INSPECTION',
  'VIEW_COMPLAINT': 'VIEW_COMPLAINT',
  'MANAGE_COMPLAINTS': 'MANAGE_COMPLAINTS',
  'APPROVE_COMPLAINT': 'APPROVE_COMPLAINT',
  'START_SUPERVISION': 'START_SUPERVISION',
  'VIEW_SUPERVISION_RESULT': 'VIEW_SUPERVISION_RESULT',
  'ADD_RISK_EVALUATION': 'ADD_RISK_EVALUATION',
  'APPROVE_RISK_EVALUATION': 'APPROVE_RISK_EVALUATION',
  'IMPORT_NMA_PLAN': 'IMPORT_NMA_PLAN',
  'VIEW_CLASSIFIER_LIST': 'VIEW_CLASSIFIER_LIST',
  'VIEW_MY_ORDER_LIST': 'VIEW_MY_ORDER_LIST',
 } as const;

export type UserPermissions = (keyof typeof userPermissions);

export const userRoles = {
  //Administratorius
  'ADMINISTRATOR': 'ADM',
  //Paslaugų vadovas
  'SERVICE_MANAGER': 'PASV',
  //Kontrolės vadovas
  'CONTROL_MANAGER': 'KONV',
  //Planuotojas
  'PLANNER': 'PLA',
  //Kontrolierius
  'INSPECTOR': 'KON',
  //Vadovas
  'HEAD_MANAGER': 'VAD',
 } as const;

export type UserRoles = (keyof typeof userRoles);
