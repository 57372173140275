<div class="header-sub">
  <div class="header-sub-left">
    <div class="back">
      <button class="btn btn-opacity my-lg-2 my-xl-0" [disabled]="backButtonDisabled"
        (click)="onBackButtonClick($event)">{{backButtonTitle}}</button>
    </div>
    <div class="title">
      {{title}}<span *ngIf="subTitle">, </span><span class="subtitle" *ngIf="subTitle">{{subTitle}}</span>
    </div>
  </div>

  @if(saveButtonPermitted && !saveButtonDisabled){
    <div class="header-sub-right">
      <div class="action ">
          <button class="btn btn-min160 my-lg-2 my-xl-0" (click)="onSaveButtonClick($event)" >{{saveButtonTitle}}</button>
      </div>
    </div>
  }

  <ng-content>
    @if(secondaryButtonPermitted && !secondaryButtonDisabled){
      <div class="header-sub-right">
        <div class="action">
          <button class="btn btn-min160 my-lg-2 my-xl-0" (click)="onSecondaryButtonClick($event)">{{secondaryButtonTitle}}</button>
        </div>
      </div>
    }
  </ng-content>
  <div class="clear"></div>
</div>
