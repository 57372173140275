import { Component, Input } from "@angular/core";

@Component({
  selector: '[disableForm]',
  styles: [`
    fieldset {
      display: block;
      margin: unset;
      padding: unset;
      border: unset;
    }
  `],
  template: `
    <fieldset [disabled]="disableForm">
      <ng-content></ng-content>
    </fieldset>
  `,
  standalone: true
})
export class DisableFormDirective {

  @Input('disableForm') disableForm!: boolean;

  constructor() {}
}