import { mapEnumToOptions } from '../helpers/form-utils';
import { Pipe, PipeTransform } from '@angular/core';

export enum EvaluationRule {
  ActivityRisk = 1,
  CapacityIndicatorRisk = 2,
  MarketRisk = 3,
  InspectionRisk = 4,
  AuditRisk = 5,
  DeficienciesRisk = 6,
  IncidentsRisk = 7
}

export const evaluationRuleOptions = mapEnumToOptions<typeof EvaluationRule>(EvaluationRule);
export const evaluationRuleLabel = (e: EvaluationRule): string => {
  switch (e) {
    case EvaluationRule.ActivityRisk:
      return 'Veikla';
    case EvaluationRule.CapacityIndicatorRisk:
      return 'Apimtis/pajėgumai';
    case EvaluationRule.MarketRisk:
      return 'Rinka';
    case EvaluationRule.InspectionRisk:
      return 'Patikrinimas';
    case EvaluationRule.AuditRisk:
      return 'Auditas';
    case EvaluationRule.DeficienciesRisk:
      return 'Trūkumų šalinimo kontrolė';
    case EvaluationRule.IncidentsRisk:
      return 'Incidentai';
    default:
      return 'NEŽINOMA';
  }
};

export const evaluationRuleOptionsSelect = mapEnumToOptions<typeof EvaluationRule>(EvaluationRule).map(e => {
  return {
    id: e.value,
    name: evaluationRuleLabel(EvaluationRule[ e.key as keyof typeof EvaluationRule ]),
    code: '',
  };
});

@Pipe({ name: 'evaluationRuleLabel' })
export class EvaluationRulePipe implements PipeTransform {
  transform(value: EvaluationRule): string {
    return evaluationRuleLabel(value);
  }
}