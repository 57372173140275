import { mapEnumToOptions } from '../helpers/form-utils';
import { Pipe, PipeTransform } from '@angular/core';

export enum ActivityStatus {
  InEvaluation = 1,
  TemporaryApproved = 2,
  Active = 3,
  Paused = 4,
  Canceled = 5
}

export const activityStatusOptions = mapEnumToOptions(ActivityStatus);

export const activityStatusLabel = (e: ActivityStatus): string => {
  switch (e) {
    case ActivityStatus.InEvaluation:
      return 'Vertinamas';
    case ActivityStatus.TemporaryApproved:
      return 'Laikinai patvirtintas'
    case ActivityStatus.Active:
      return 'Aktyvus';
    case ActivityStatus.Paused:
      return 'Sustabdytas';
    case ActivityStatus.Canceled:
      return 'Panaikintas';
    default:
      return '-';
  }
};
export const activityStatusOptionsSelect = mapEnumToOptions<typeof ActivityStatus>(ActivityStatus).map(e => {
  return {
    id: Number(e.value),
    name: activityStatusLabel(e.value),
    code: '',
  };
});

@Pipe({ name: 'activityStatusLabel' })
export class ActivityStatusPipe implements PipeTransform {
  transform(value: ActivityStatus): string {
    return activityStatusLabel(value);
  }
}
