<div class="modal-header">
  <h4 class="modal-title">{{ options ? options.title : "" }}</h4>
</div>
<div *ngIf="options?.htmlFormat else divHmtl" class="modal-body">
  <p [innerHTML]="options ? options.message : ''"></p>
</div>
<ng-template #divHmtl>
  <div class="modal-body" >
    <p>{{ options ? options.message : "" }}</p>
  </div>
</ng-template>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="no()">Ne</button>
  <button type="button" class="btn btn-danger" (click)="yes()">Taip</button>
</div>
