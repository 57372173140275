export class GlobalSettings {
    static UserActivitySessionTimeout = 900; // 900 sec = 15 min.
    static UserActivityAutomaticLogoutTime = 30; // 60 sec = 1 min.

    static BackendErrorSnackbarDuration = 30;
    static BackendWarningSnackbarDuration = 15;
    static BackendSuccessSnackbarDuration = 5;
    static BackendInfoSnackbarDuration = 15;

    static SessionTimeTillTimer = 27; // minutes
    static SessionTimer = 3; // minutes
}

export class Constants {
    static DEFAULT_INSPECTION_DURATION_TIME_IN_DAYS = 5
    static MEGABYTE = 1024*1024
    static DOCX_LINE_LENGTH = 105
    static EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    static PHONE_NUMBER_REGEX = /^\+3706\d{7}$/;
    static FA_CODE_REGEX = /^((?!0)[0-9]{11})$/;
    static JA_CODE_REGEX = /^((?!0)[0-9]{9}|(?!0)[0-9]{11})$/;
}