import {Injectable} from "@angular/core";
import {Notification, NotificationType} from "../types/notification.types";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private id = 0;
  public queue: Notification[] = [];

  info(message: string) {
    this.show('info', message)
  }
  success(message: string) {
    this.show('success', message)
  }
  warning(message: string) {
    this.show('warning', message)
  }
  error(message: string) {
    this.show('error', message)
  }

  private show(type: NotificationType, message: string) {
    this.queue = [...this.queue, { id: this.id, type, message }]
    this.id++;
  }

  public dismiss(id: number) {
    this.queue = this.queue.filter(q => q.id !== id)
  }

}
