import { mapEnumToOptions } from '../helpers/form-utils';
import { Pipe, PipeTransform } from '@angular/core';

export enum SubjectLegalForm {
  Fizinis = 1,
  Juridinis = 2
}

export const subjectLegalFormOptions = mapEnumToOptions(SubjectLegalForm);

export const subjectLegalFormLabel = (e: SubjectLegalForm): string => {
  switch (e) {
    case SubjectLegalForm.Fizinis:
      return 'Fizinis';
    case SubjectLegalForm.Juridinis:
      return 'Juridinis';
    default:
      return '-';
  }
};

export const subjectLegalFormOptionsSelect = mapEnumToOptions<typeof SubjectLegalForm>(SubjectLegalForm).map(e => {
  return {
    id: Number(e.value),
    name: subjectLegalFormLabel(e.value),
    code: '',
  };
});

@Pipe({ name: 'subjectLegalFormLabel' })
export class SubjectLegalFormPipe implements PipeTransform {
  transform(value: SubjectLegalForm): string {
    return subjectLegalFormLabel(value);
  }
}