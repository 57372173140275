import { mapEnumToOptions } from 'src/app/shared/helpers/form-utils';

export enum Classifiers {
  ClsfActivityType = 1,
  ClsfActivity = 2,
  ClsfDataSource = 3,
  ClsfOrganization = 5,
  //ClsfFrequencyOfInspection = 11,
  ClsfAnimalSpecy = 14,
  ClsfAnimalSpecyType = 15,
  ClsfSampleType = 17,
  ClsfInspectionObjective = 18,
  ClsfSupervisionObjective = 19,
  ClsfPurposeOfSampling = 20,
  ClsfControlGroup = 25,
  ClsfInspectionSubtype = 28,
  ClsfSelfControlSystemState = 29,
  ClsfPosition = 31,
  ClsfQuestionnaire = 34,
  ClsfReasonForNotApplyingTheSanction = 37,
  ClsfSanction = 38,
  ClsfSanctionGroup = 39,
  //ClsfSanctionLegislation = 41,
  ClsfComplaintMethod = 42,
  ClsfInformationForm = 43,
  ClsfIdentifiedViolation = 47,
  ClsfAppliedExposureMeasure = 48,
  ClsfOtherInstitution = 51,
  ClsfUnitOfMeasurement = 52,
  ClsfResearchLaboratory = 53,
  ClsfSamplingLocation = 54,
  ClsfPayer = 55,
  ClsfQualitySystemProgram = 57,
  ClsfRequirementGroup = 58,
  ClsfLegislation = 59,
  ClsfRequirement = 60,
  ClsfRiskFactor = 101,
  ClsfCapacityIndicator = 102,
  ClsfLegislationPart = 103,
  ClsfEconomicActivityType = 104,
  ClsfMarketIndicator = 105
}

export const clsfOptions = mapEnumToOptions(Classifiers);
