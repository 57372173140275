import { ClsfDropdownItem } from 'src/app/modules/classifiers/clsf-dto';
import { mapEnumToOptions } from '../helpers/form-utils';
import { Pipe, PipeTransform } from '@angular/core';

// KL-16
export enum DateMonth {
  JAN = 'JAN',
  FEB = 'FEB',
  MAR = 'MAR',
  APR = 'APR',
  MAY = 'MAY',
  JUN = 'JUN',
  JUL = 'JUL',
  AUG = 'AUG',
  SEP = 'SEP',
  OCT = 'OCT',
  NOV = 'NOV',
  DEC = 'DEC',
}

export const dateMonthValue = (e: DateMonth): number => {
  switch (e) {
    case DateMonth.JAN:
      return 1;
    case DateMonth.FEB:
      return 2;
    case DateMonth.MAR:
      return 3;
    case DateMonth.APR:
      return 4;
    case DateMonth.MAY:
      return 5;
    case DateMonth.JUN:
      return 6;
    case DateMonth.JUL:
      return 7;
    case DateMonth.AUG:
      return 8;
    case DateMonth.SEP:
      return 9;
    case DateMonth.OCT:
      return 10;
    case DateMonth.NOV:
      return 11;
    case DateMonth.DEC:
      return 12;
  }
};
export const dateMonthOptions = mapEnumToOptions<typeof DateMonth>(DateMonth);
export const dateMonthLabel = (e: DateMonth): string => {
  switch (e) {
    case DateMonth.JAN:
      return 'Sausis';
    case DateMonth.FEB:
      return 'Vasaris';
    case DateMonth.MAR:
      return 'Kovas';
    case DateMonth.APR:
      return 'Balandis';
    case DateMonth.MAY:
      return 'Gegužė';
    case DateMonth.JUN:
      return 'Birželis';
    case DateMonth.JUL:
      return 'Liepa';
    case DateMonth.AUG:
      return 'Rugpjūtis';
    case DateMonth.SEP:
      return 'Rugsėjis';
    case DateMonth.OCT:
      return 'Spalis';
    case DateMonth.NOV:
      return 'Lapkritis';
    case DateMonth.DEC:
      return 'Gruodis';
  }
  return e;
};
export const dateMonthDropdownItems = mapEnumToOptions<typeof DateMonth>(DateMonth).map(e => {
  return {
    id: dateMonthValue(DateMonth[ e.key as keyof typeof DateMonth ]),
    name: dateMonthLabel(DateMonth[ e.key as keyof typeof DateMonth ]),
  } as ClsfDropdownItem;
});

@Pipe({ name: 'dateMonthLabel' })
export class DateMonthLabelPipe implements PipeTransform {
  transform(value?: DateMonth): string | undefined {
    return value ? dateMonthLabel(value) : value;
  }
}
