import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-secondary-menu-with-cancel',
  templateUrl: './secondary-menu-with-cancel.component.html',
  styleUrls: ['./secondary-menu-with-cancel.component.less']
})
export class SecondaryMenuWithCancelComponent {

  @Input()
  title: string = 'Default Title';

  @Input()
  saveButtonTitle: string = 'Išsaugoti objektą'

  @Input()
  cancelButtonTitle: string = 'Atšaukti';

  @Input()
  isDisabled: boolean = false;

  @Input()
  isPermitted: boolean = true;

  @Output()
  onCancelClick = new EventEmitter<any>();

  @Output()
  onSubmitClick = new EventEmitter<any>();

  onCancelButtonClick(event: any) {
    this.onCancelClick.emit(event);
  }

  onSaveButtonClick(event: any) {
    this.onSubmitClick.emit(event);
  }
}
