import { mapEnumToOptions } from '../helpers/form-utils';
import { Pipe, PipeTransform } from '@angular/core';

export enum SubjectType {
  BusinessEntity = 1,
  NaturalPerson = 2,
  Territory = 3
}

export const subjectTypeOptions = mapEnumToOptions(SubjectType);

export const subjectTypeLabel = (e: SubjectType): string => {
  switch (e) {
    case SubjectType.BusinessEntity:
      return 'Ūkio subjektas';
    case SubjectType.NaturalPerson:
      return 'Fizinis asmuo';
    case SubjectType.Territory:
      return 'Teritorija';
    default:
      return '-';
  }
};
export const subjectTypeOptionsSelect = mapEnumToOptions<typeof SubjectType>(SubjectType).map(e => {
  return {
    id: Number(e.value),
    name: subjectTypeLabel(e.value),
    code: '',
  };
});

@Pipe({ name: 'subjectTypeLabel' })
export class SubjectTypePipe implements PipeTransform {
  transform(value: SubjectType): string {
    return subjectTypeLabel(value);
  }
}
