import { mapEnumToOptions } from '../helpers/form-utils';
import { Pipe, PipeTransform } from '@angular/core';

/// KL-11
/// Patikrinimo dažnumas
export enum FrequencyOfInspection {
  None = 0,
  TwicePerYear,
  ThreeTimesPerYear,
  OncePer1,
  OncePer2,
  OncePer3,
  OncePer4,
  OncePer5,
  OncePer6,
  OncePer7,
  OncePer8,
  OncePer9,
  OncePer10,
}

export const frequencyOfInspectionOptions = mapEnumToOptions<typeof FrequencyOfInspection>(FrequencyOfInspection);
export const frequencyOfInspectionLabel = (e: FrequencyOfInspection): string => {
  switch (e) {
    case FrequencyOfInspection.None:
      return 'Nėra';
    case FrequencyOfInspection.TwicePerYear:
      return '2 kartus per metus';
    case FrequencyOfInspection.ThreeTimesPerYear:
      return '3 kartus per metus';
    case FrequencyOfInspection.OncePer1:
      return 'Kartą per metus';
    case FrequencyOfInspection.OncePer2:
      return 'Kartą per 2 metus';
    case FrequencyOfInspection.OncePer3:
      return 'Kartą per 3 metus';
    case FrequencyOfInspection.OncePer4:
      return 'Kartą per 4 metus';
    case FrequencyOfInspection.OncePer5:
      return 'Kartą per 5 metus';
    case FrequencyOfInspection.OncePer6:
      return 'Kartą per 6 metus';
    case FrequencyOfInspection.OncePer7:
      return 'Kartą per 7 metus';
    case FrequencyOfInspection.OncePer8:
      return 'Kartą per 8 metus';
    case FrequencyOfInspection.OncePer9:
      return 'Kartą per 9 metus';
    case FrequencyOfInspection.OncePer10:
      return 'Kartą per 10 metų';
  }
  return '';
};

export const frequencyOfInspectionOptions2 = mapEnumToOptions<typeof FrequencyOfInspection>(FrequencyOfInspection).map(e => {
  return {
    id: Number(e.value),
    name: frequencyOfInspectionLabel(FrequencyOfInspection[ e.key as keyof typeof FrequencyOfInspection ]),
    code: '',
  };
});

@Pipe({ name: 'frequencyOfInspectionLabel' })
export class FrequencyOfInspectionLabelPipe implements PipeTransform {
  transform(value: FrequencyOfInspection): string {
    return frequencyOfInspectionLabel(value);
  }
}
