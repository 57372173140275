import { mapEnumToOptions } from '../helpers/form-utils';
import { Pipe, PipeTransform } from '@angular/core';

/// KL-4
/// Rizikos grupė
export enum RiskGroup {
  //Labai maža
  None = 0,
  //Labai maža
  VerySmall = 1,
  //Maža
  Small = 2,
  //Vidutinė
  Medium = 3,
  //Didelė
  High = 4,
}

export const riskGroupOptions = mapEnumToOptions<typeof RiskGroup>(RiskGroup);
export const riskGroupLabel = (e: RiskGroup): string => {
  switch (e) {
    case RiskGroup.High:
      return 'Didelė';
    case RiskGroup.Medium:
      return 'Vidutinė';
    case RiskGroup.Small:
      return 'Maža';
    case RiskGroup.VerySmall:
      return 'Labai maža';
    default:
      return 'Nenustatyta';
  }
};

export const riskGroupOptionsSelect = mapEnumToOptions<typeof RiskGroup>(RiskGroup).map(e => {
  return {
    id: Number(e.value),
    name: riskGroupLabel(RiskGroup[ e.key as keyof typeof RiskGroup ]),
    code: '',
  };
});

@Pipe({ name: 'riskGroupLabel' })
export class RiskGroupLabelPipe implements PipeTransform {
  transform(value?: RiskGroup): string {
    return value != null ? riskGroupLabel(value) : '';
  }
}


