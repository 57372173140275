import { Pipe, PipeTransform } from "@angular/core";
import { mapEnumToOptions } from "../helpers/form-utils";

export enum OrderStatus {
    Nepasirasytas = 1,
    NepasirasytasRedaguotas = 2,
    Pasirasytas = 3,
    VykdomasPatikrinimasVietoje = 4,
    VykdomasTolesnisProcesas = 5,
    Ivykdytas = 6,
    Panaikintas = 7,
    Neivykdytas = 8
}

export const orderStatusOptions = mapEnumToOptions(OrderStatus);

export const orderStatusLabel = (e: OrderStatus): any => {
    switch (e) {
    case OrderStatus.Nepasirasytas:
        return 'Naujas (nepasirašytas)';
    case OrderStatus.VykdomasPatikrinimasVietoje:
        return 'Vykdomas: Patikrinimas vietoje';
    case OrderStatus.VykdomasTolesnisProcesas:
        return 'Vykdomas: Tolesnis procesas';
    case OrderStatus.Ivykdytas:
        return 'Įvykdytas';
    case OrderStatus.Panaikintas:
        return 'Panaikintas';
    case OrderStatus.NepasirasytasRedaguotas:
        return 'Redaguotas (nepasirašytas)';
    case OrderStatus.Pasirasytas:
        return 'Pasirašytas';
    case OrderStatus.Neivykdytas:
        return 'Neįvykdytas';
    }
};

export const orderStatusOptions2 = mapEnumToOptions<typeof OrderStatus>(OrderStatus).map(e => {
    return {
    id: e.key,
    name: orderStatusLabel(OrderStatus[ e.key as keyof typeof OrderStatus ]),
    code: '',
    };
});

@Pipe({ name: 'orderStatusLabel' })
export class OrderStatusPipe implements PipeTransform {
    // transform(value: string): string {
    //   return orderStatusLabel(OrderStatus[value as keyof typeof OrderStatus]);
    // }
    transform(value?: OrderStatus): string | undefined {
    return value ? orderStatusLabel(value) : value;
    }
}
