import { Component } from '@angular/core';
import { ConfirmOptions } from '../confirm-options.model';
import { ConfirmState } from '../confirm-state.model';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.less'],
})
export class ConfirmModalComponent {
  options?: ConfirmOptions;

  constructor(private state: ConfirmState) {
    this.options = state.options;
  }

  yes() {
    if (this.state.modal) this.state.modal.close('confirmed');
  }

  no() {
    if (this.state.modal) this.state.modal.dismiss('not confirmed');
  }
}
