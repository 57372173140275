import { mapEnumToOptions } from '../helpers/form-utils';
import { Pipe, PipeTransform } from '@angular/core';

// KL-26 - pavedimo tipas
export enum InspectionType {
  Inspection = 1,
  Supervision = 2,
}

export const inspectionTypeOptions = mapEnumToOptions<typeof InspectionType>(InspectionType);
export const inspectionTypeLabel = (e: InspectionType): string => {
  switch (e) {
    case InspectionType.Inspection:
      return 'Patikrinimas';
    case InspectionType.Supervision:
      return 'Priežiūra';
    default:
        return 'NEŽINOMA';
  }
};

export const inspectionTypeOptionsSelect = mapEnumToOptions<typeof InspectionType>(InspectionType).map(e => {
  return {
    id: e.key,
    name: inspectionTypeLabel(InspectionType[ e.key as keyof typeof InspectionType ]),
    code: '',
  };
});

@Pipe({ name: 'inspectionTypeLabel' })
export class InspectionTypeLabelPipe implements PipeTransform {
  transform(value: InspectionType | string): string {
    return inspectionTypeLabel(
      typeof value === 'string'
      ? InspectionType[ value as keyof typeof InspectionType ]
      : value
    );
  }
}
