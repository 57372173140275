<div class="form-group check-box-multiselect-input">
  <label *ngIf="label !== undefined">{{ label }}</label>
  <div class="checkbox checkbox-block">
    <div
      class="checkbox-holder checked"
      *ngFor="let item of items; let i = index"
    >
      <label
      >{{ item.displayValue }}
        <input type="checkbox" name="{{ name }}_{{ i }}" [(ngModel)]="item.checked" (ngModelChange)="checkboxChange()"/>
        <span class="checkboxbtn"></span>
      </label>
    </div>
  </div>
</div>
