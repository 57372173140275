import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RestBaseService {
  readonly reqOptions = {};
  
  constructor(protected http: HttpClient) {}

  GetAllAsync<T>(path: string): Observable<T> {
    return this.http.get<T>(`${path}`, this.reqOptions);
  }

  GetFilteredAsync<T>(filter: any, path: string): Observable<T> {
    const queryString = Object.keys(filter || {})
      .map((key) => clearNullParams(key, filter[ key ]))
      .filter(Boolean)
      .join('&');
    return this.http.get<T>(`${path}?${queryString}`, this.reqOptions);
  }

  GetByIdAsync<T>(id: number, path: string): Observable<T> {
    return this.http.get<T>(`${path}/${id}`, this.reqOptions);
  }

  CreateAsync<T>(insertDTO: any, path: string): Observable<T> {
    return this.http.post<T>(`${path}`, insertDTO, this.reqOptions);
  }

  DeleteAsync<T>(id: number, path: string): Observable<T> {
    return this.http.delete<T>(`${path}/${id}`, this.reqOptions);
  }

  DeleteAsyncWithBody<T>(id: number, path: string, body: any): Observable<T> {
    return this.http.delete<T>(`${path}/${id}`, { ...this.reqOptions, body });
  }

  UpdateAsync<T>(id: number, updateDTO: any, path: string): Observable<T> {
    return this.http.put<T>(`${path}/${id}`, updateDTO, this.reqOptions);
  }

  UpdateAsyncMany<T>(updateDTO: any, path: string): Observable<T> {
    return this.http.put<T>(`${path}`, updateDTO, this.reqOptions);
  }

  CreateAndDownloadFileAsync<T>(insertDTO: any, path: string): any {
    return this.http.post(path, insertDTO, {
      observe: 'response',
      responseType: 'blob',
    });
  }

  GetAttachedFile<T>(path: string): any {
    return this.http.get(path, {
      observe: 'response',
      responseType: 'blob',
    });
  }

  PostForm<T>(path: string, formData: FormData): Observable<T> {
    return this.http.post<T>(`${path}`, formData, this.reqOptions);
  }
}

function clearNullParams(key: string, filterKey: string | string[]): string{
  if(Array.isArray(filterKey))
    return filterKey.map(param => filterKey == null || param === 'null' || param === '' ? '' : key + '=' + param)
    .join('&')
  else 
    return filterKey == null || filterKey === 'null' || filterKey === '' ? '' : key + '=' + filterKey;
}