import { Directive, TemplateRef } from '@angular/core';
import { ConfirmState } from './confirm-state.model';

@Directive({
  selector: '[confirmModalTemplate]',
})
export class ConfirmTemplateDirective {
  constructor(
    confirmTemplate: TemplateRef<any>,
    state: ConfirmState
  ) {
    state.template = confirmTemplate;
  }
}
