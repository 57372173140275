<app-header-wrapper [ngClass]="mobileMenu ? 'mobile-menu' : ''">
  <div class="header-top">
    <div class="header-top-left header-block">
      <div class="logo header-block">
        <img src="assets/images/logo.png" alt="VMVT"/>
        <span>Valstybinė maisto ir <br/>veterinarijos tarnyba</span>
      </div>
      <div class="menu header-block">
        <ul>
          <li *ngIf="userAuthService.canViewSubjectList">
            <a
              [routerLink]="['/subject']"
              routerLinkActive="active"
              title="">
              Subjektai
            </a>
          </li>
          <li *ngIf="userAuthService.canViewControlObjectList">
            <a
              [routerLink]="['/control-objects']"
              routerLinkActive="active"
              title="">
              Kontrolės objektai
            </a>
          </li>
          <li *ngIf="userAuthService.canViewPlan">
            <a [routerLink]="['/plans']" routerLinkActive="active" title="">
            Planas
            </a>
          </li>
          <li *ngIf="userAuthService.canViewOrderList">
            <a
              [routerLink]="['/orders']"
              routerLinkActive="active"
              title=""
              [routerLinkActiveOptions]="{ exact: true }"
            >
            Pavedimai
            </a>
          </li>
          <li *ngIf="userAuthService.canViewMyOrders">
            <a
              [routerLink]="['/orders/my-orders']"
              routerLinkActive="active"
              title=""
              [routerLinkActiveOptions]="{ exact: true }"
            >
            Mano pavedimai
            </a>
          </li>
          <li *ngIf="userAuthService.canViewComplaints">
            <a
              [routerLink]="['/reports']"
              routerLinkActive="active"
              title="">
              Pranešimai
            </a>
          </li>
          <li *ngIf="userAuthService.canOpenAdmin">
            <a
              [routerLink]="['/admin']"
              routerLinkActive="active"
              title="">
              Admin
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="header-top-right header-block">
      <app-environment></app-environment>
      <app-user-info></app-user-info>
    </div>
    <div class="header-top-mobile" (click)="toggleMobileMenu()">
      <span></span>
      <span></span>
      <span></span>
    </div>
    <div class="clear"></div>
  </div>
</app-header-wrapper>
