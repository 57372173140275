import { Component, EventEmitter, forwardRef, HostBinding, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CheckboxMultiSelectItem } from './models/checkbox-multi-select-item.model';

@Component({
  selector: 'app-checkbox-multi-select',
  templateUrl: './checkbox-multi-select.component.html',
  styleUrls: ['./checkbox-multi-select.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxMultiSelectComponent),
      multi: true,
    },
  ],
})
export class CheckboxMultiSelectComponent
  implements ControlValueAccessor, OnInit {
  @Input()
  name: string = 'multiselect';
  @Input() checkedWhenSelected = true;

  @Input()
  items: CheckboxMultiSelectItem[] = [];

  @Input()
  label?: string;

  @HostBinding('style.max-height.px')
  @Input() maxHeight?: number = 500;

  @HostBinding('class.negative-v-margins')
  @Input() negativeVMargins?: boolean;

  @HostBinding('class.negative-h-margins')
  @Input() negativeHMargins?: boolean;

  @Output()
  onCheckboxClick = new EventEmitter<any>();

  keys: number[] = [];

  private onTouched!: Function;

  private onChanged!: Function;

  constructor() {
  }

  ngOnInit() {
  }

  writeValue(value: number[]): void {
    this.keys = value;
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  checkboxChange() {
    let checkedValues = this.items.filter((p) => p.checked === this.checkedWhenSelected).map((e) => e.key);
    this.selectKeys();
    this.onCheckboxClick.emit(checkedValues);
  }

  selectKeys() {
    this.onTouched();
    this.onChanged(
      this.items.filter((p) => p.checked === this.checkedWhenSelected).map((e) => e.key)
    );
  }
}
