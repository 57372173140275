import { Injectable, TemplateRef } from "@angular/core";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmOptions } from "./confirm-options.model";

@Injectable()
export class ConfirmState {
  options?: ConfirmOptions;

  modal?: NgbModalRef;

  template?: TemplateRef<any>;
}