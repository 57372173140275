import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import { EventListFilterDto } from 'src/app/modules/control-object/models/event-list-filter-dto';
import { ControlObjectEventListItemResponseDto, EventListItemResponseDto } from 'src/app/modules/control-object/models/event-list-item-response-dto.model';
import { InspectionKind } from '../enums/inspection-kind.enum';
import { PagedResultDto } from '../models/paging-sorting';
import { ListService } from '../services/list.service';
import { ControlObjectEventService } from 'src/app/modules/control-object/services/control-object-event.service';
import { UserAuthorizationService } from 'src/app/modules/user/user-authorization.service';

@Component({
  selector: 'app-events-table',
  templateUrl: './events-table.component.html',
  styleUrls: ['./events-table.component.less'],
  providers: [ListService],
})
export class EventsTableComponent implements OnInit, OnChanges {
  
  @Input()
  resourceId: number = 0;

  @Input()
  inspectionKind?: InspectionKind = undefined;

  @Input()
  rowCreateActionButtonTitle: string = '';

  @Input()
  rowCreateActionButtonPermitted = false;

  @Input()
  rowViewActionButtonTitle: string = '';

  @Input()
  noRecordsTitle: string = '';

  @Input()
  title: string = '';

  @Input()
  refreshListTime?: number;

  @Output()
  onRowActionButtonClick = new EventEmitter<ControlObjectEventListItemResponseDto>();

  @Output()
  onEditEventClick = new EventEmitter<ControlObjectEventListItemResponseDto>();

  @Output()
  onDeleteEventClick = new EventEmitter<ControlObjectEventListItemResponseDto>();

  rows: PagedResultDto<ControlObjectEventListItemResponseDto> = {};

  isPlanned = false;
  
  constructor(
    private controlObjectEventService: ControlObjectEventService,
    public readonly list: ListService<EventListFilterDto>,
    public userAuthService: UserAuthorizationService
  ) {}

  ngOnInit(): void {
    this.getControlObjectData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['refreshListTime'] && !changes['refreshListTime'].firstChange) {
      this.getControlObjectData();
    }
  }

  getControlObjectData(searchDto: EventListFilterDto = {}) {
    this.isPlanned = this.inspectionKind === InspectionKind.Scheduled;
    searchDto.isPlanned = this.isPlanned;
    
    const controlObjectsStreamCreator = (query: any) =>
      this.controlObjectEventService.getFilteredList(this.resourceId, {
        ...query,
        ...searchDto,
      });

    this.list
      .hookToQuery(controlObjectsStreamCreator)
      .subscribe((response) => {
        this.rows = response;
      });
  }

  rowActionClickHandler(row: ControlObjectEventListItemResponseDto) {
   this.onRowActionButtonClick.emit(row);
  }

  editEvent(row: ControlObjectEventListItemResponseDto) {
    this.onEditEventClick.emit(row);
  }

  deleteEvent(row: ControlObjectEventListItemResponseDto) {
    this.onDeleteEventClick.emit(row);
  }
}
