<div class="block ">
    <div class="table-top ">
        <div class="table-top-left">
            <div class="title">
                {{title}}
            </div>
        </div>
        <div class="table-top-right">
            
        </div>
        <div class="clear"></div>
    </div>

    <div class="block bordered  hidden show">
        <div class="table-row table-row-inline">
            <div class="table-col" *ngFor="let item of items">
                <p class="title">{{item.label}}</p>
                <p><strong>{{item.displayValue}}</strong></p>
            </div>
        </div>
    </div>

    <div class="row hidden-divider">
        <div class="col">
            <div class="divider"></div>
        </div>
    </div>
</div>