import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import { faCalendar, faCalendarDay } from '@fortawesome/free-solid-svg-icons';
import {
  NgbDateAdapter,
  NgbDateNativeAdapter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';

const noop = () => {};

export const DATE_PICKER_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DatePickerAsFormControlComponent),
  multi: true,
};

@Component({
  selector: 'app-date-picker-as-form-control',
  templateUrl: './date-picker-as-form-control.component.html',
  styleUrls: ['./date-picker-as-form-control.component.less'],
  providers: [
    DATE_PICKER_CONTROL_VALUE_ACCESSOR,
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
  ],
})
export class DatePickerAsFormControlComponent implements ControlValueAccessor, OnInit {
  dateControl: FormControl = new FormControl();
  @Input()
  name: string = 'datepicker';

  @Input()
  placeholder: string = 'yyyy-mm-dd';

  @Input()
  label?: string;

  @Input() set errorMessage(value: string) {
   this._errorMessage = value;
   if (value) {
     this.dateControl.setErrors({'incorrect': true});
     this.dateControl.markAsTouched();
   } else {
     this.dateControl.setErrors(null);
   }
  }
  get errorMessage(): string {
    return this._errorMessage;
  }

  calendarIcon = faCalendarDay;

  private onTouchedCallback: () => void = noop;

  private onChangeCallback: (_: any) => void = noop;
  private _errorMessage = '';
  constructor() {}

  ngOnInit() {
    this.dateControl.valueChanges.subscribe((val: NgbDateStruct | undefined) => {
      this.onChangeCallback(val);
    });
  }
  onBlur() {
    this.onTouchedCallback();
  }

  writeValue(value: NgbDateStruct) {
    this.dateControl.setValue(value, {emitEvent: false});
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
}

