<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <title>VKS</title>
    <meta name="viewport" content="width=device-width, initial-scale=1" />
  </head>
  <body class="">
    <div class="wrapper">
      <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
  </body>
</html>
