import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PagedResultDto } from 'src/app/shared/models/paging-sorting';
import { RestBaseService } from 'src/app/shared/services/rest-base.service';
import { environment } from 'src/environments/environment';
import { CombinedEventCreateDto } from '../create-event-modal/combined-event-create-dto.model';
import { EventListFilterDto } from '../models/event-list-filter-dto';
import { ControlObjectEventListItemResponseDto, EventListItemResponseDto } from '../models/event-list-item-response-dto.model';

@Injectable({
  providedIn: 'root',
})
export class ControlObjectEventService extends RestBaseService {
  constructor(http: HttpClient) {
    super(http);
  }

  getFilteredList(
    id: number,
    filter: EventListFilterDto
  ): Observable<PagedResultDto<ControlObjectEventListItemResponseDto>> {
    return this.GetFilteredAsync<PagedResultDto<ControlObjectEventListItemResponseDto>>(
      filter,
      `${environment.api.controlObjectEventsApiUrl}/${id}`
    );
  }

  createMany(
    coIds: number[],
    eventCreateDto: CombinedEventCreateDto
  ): Observable<void> {
    const queryString = coIds.filter(Boolean).join('&coIds=');
    return this.CreateAsync<void>(
      eventCreateDto,
      `${environment.api.controlObjectEventsApiUrl}?coIds=${queryString}`
    );
  }
}
