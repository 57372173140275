import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ComponentMode } from 'src/app/shared/enums/component-mode.enum';

@Component({
  selector: 'app-secondary-menu',
  templateUrl: './secondary-menu.component.html',
  styleUrls: ['./secondary-menu.component.less']
})
export class SecondaryMenuComponent {
  @Input()
  title: string = 'Default Title';

  @Input()
  subTitle?: string = undefined;

  @Input() set mode(value: ComponentMode) {
    if(this.subTitle) return;
    if(value == 'view'){
      this.subTitle = "PERŽIŪROS";
    } else if(value == 'edit'){
      this.subTitle = "REDAGAVIMO";
    } else if(value == 'create') {
      this.subTitle = "NAUJO ĮRAŠO SUKŪRIMO";
    }
    this.subTitle += " REŽIMAS";
  }

  //Save button
  @Input() saveButtonTitle: string = 'Išsaugoti';
  @Input() saveButtonDisabled: boolean = false;
  @Input() saveButtonPermitted: boolean = true;
  @Output() onSubmitClick = new EventEmitter<any>();

  //Back button
  @Input() backButtonTitle: string = 'Grįžti';
  @Input() backButtonDisabled: boolean = false;
  @Output() onBackClick = new EventEmitter<any>();

  //Secondary button
  @Input() secondaryButtonTitle: string = 'Nenustatyta';
  @Input() secondaryButtonDisabled: boolean = true;
  @Input() secondaryButtonPermitted: boolean = false;
  @Output() onSecondaryClick = new EventEmitter<any>();

  //private readonly canGoBack: boolean;

  constructor(
    private readonly _location: Location,
    private readonly _router: Router,
    private readonly _route: ActivatedRoute) {
    //this.canGoBack = !!(this._router.getCurrentNavigation()?.previousNavigation);
  }

  onBackButtonClick(event: any) {
    this._location.back();
    // if (this.canGoBack) {
    //   // We can safely go back to the previous location as
    //   // we know it's within our app.
    //   this._location.back();
    // } else {
    //   // There's no previous navigation.
    //   // Here we decide where to go.
    //   // upper level is the index page, so we go up one level.
    //   this._router.navigate(['..'], {relativeTo: this._route});
    // }
    //this.onBackClick.emit(event); // Maybe add condition if to emit event or not
  }

  onSaveButtonClick(event: any) {
    this.onSubmitClick.emit(event);
  }

  onSecondaryButtonClick(event: any) {
    this.onSecondaryClick.emit(event);
  }
}
