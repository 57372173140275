import { GlobalSettings } from './../../environments/global-settings';
import { Injectable } from '@angular/core';
import { AlertTypes } from '../shared/enums/be-msg-enum';
import { NotificationService } from '../modules/notifications/services/notification.service';

@Injectable({
  providedIn: 'root',
})
export class BackendErrorHandlerService {
  constructor(
    private notification: NotificationService
  ) {}

  public isValidationError(e: any): boolean {
    if (
      e?.status === 400 &&
      e?.title === 'One or more validation errors occurred.'
    ) {
      return true;
    } else {
      return false;
    }
  }

  renderUnhandledError(errorObj: any, code?: string): void {
    /*
  Unhandler error example
  let err = JSON.parse('{"type":"https://tools.ietf.org/html/rfc7231#section-6.5.1","title":"One or more validation errors occurred.","status":400,"traceId":"|9d961a91-48e440c22cce83c0.","errors":{"Packages[0].PackageNumber":["The PackageNumber field is required."],"Packages[0].PackageNationalNumber":["The PackageNationalNumber field is required."]}}');
  */
    if (this.isValidationError(errorObj)) {
      const fieldsMessages = [];
      // tslint:disable-next-line: forin
      for (const e in errorObj.errors) {
        for (const eInner of errorObj.errors[e]) {
          fieldsMessages.push(eInner);
        }
      }
      this.openSnackBar(
        `Back-end validacijos klaida! Trūksta duomenų laukuose: ${fieldsMessages.join()}`,
        true,
        JSON.stringify(errorObj),
        AlertTypes.Danger
      );
    } else {
      this.openSnackBar(
        `<div><strong>Neatpažinta back-end klaida!</strong></div>`,
        true,
        JSON.stringify(errorObj),
        AlertTypes.Danger
      );
    }
  }

  renderHandledError(
    errorObj: IBackendError | any,
    customDuration?: number
  ): void {
    if (this.isValidationError(errorObj)) {
      this.renderUnhandledError(errorObj);
    } else {
      this.openSnackBar(
        errorObj.message,
        false,
        errorObj.errorHashId,
        this.getAlertTypeFromMessageType(errorObj.messageType),
        customDuration
      );
    }
  }

  openSnackBar(
    message: string,
    renderAsHtml: boolean,
    errorHashId: string,
    type: string | EMessageTypes,
    customDuration?: number
  ): void {
    let duration = customDuration ? customDuration : 60;

    if (typeof type === 'number') {
      type = this.getAlertTypeFromMessageType(type);
    }

    if (!customDuration) {
      if (type === AlertTypes.Success) {
        duration = GlobalSettings.BackendSuccessSnackbarDuration;
      } else if (type === AlertTypes.Warning) {
        duration = GlobalSettings.BackendWarningSnackbarDuration;
      } else if (type === AlertTypes.Info) {
        duration = GlobalSettings.BackendInfoSnackbarDuration;
      } else {
        duration = GlobalSettings.BackendErrorSnackbarDuration;
      }
    }

    // this.snackBar.openFromComponent(BackendErrorSnackBarComponent, {
    //   duration: duration * 1000,
    //   data: { message, renderAsHtml, errorHashId, type },
    //   verticalPosition: 'top', // 'top' | 'bottom'
    //   horizontalPosition: 'end', // 'start' | 'center' | 'end' | 'left' | 'right'
    //   panelClass: [type],
    // });
    switch (type) {
      case AlertTypes.Success:
        this.notification.success(message);
        break;
      case AlertTypes.Warning:
        this.notification.warning(message);
        break;
      case AlertTypes.Info:
        this.notification.info(message);
        break;
      default:
        this.notification.error(message);
        break;
    }
  }

  SuccessMessage(message: string, data?: any, customDuration?: number): void {
    this.openSnackBar(message, false, data, AlertTypes.Success, customDuration);
  }

  WarningMessage(message: string, data?: any, customDuration?: number): void {
    this.openSnackBar(message, false, data, AlertTypes.Warning, customDuration);
  }

  WarningMessageHtml(
    message: string,
    data?: any,
    customDuration?: number
  ): void {
    this.openSnackBar(message, true, data, AlertTypes.Warning, customDuration);
  }

  InfoMessage(message: string, data?: any, customDuration?: number): void {
    this.openSnackBar(message, false, data, AlertTypes.Info, customDuration);
  }

  AlertMessage(message: string, data?: any, customDuration?: number): void {
    this.openSnackBar(message, false, data, AlertTypes.Danger, customDuration);
  }

  getAlertTypeFromMessageType(nr: EMessageTypes): AlertTypes {
    switch (nr) {
      case 1:
        return AlertTypes.Primary;
        break;

      case 2:
        return AlertTypes.Secondary;
        break;

      case 3:
        return AlertTypes.Success;
        break;

      case 5:
        return AlertTypes.Warning;
        break;

      case 6:
        return AlertTypes.Info;
        break;

      case 7:
        return AlertTypes.Light;
        break;

      case 8:
        return AlertTypes.Dark;
        break;

      case 20:
        return AlertTypes.Warning;
        break;

      case 21:
        return AlertTypes.Warning;
        break;

      case 22:
        return AlertTypes.Warning;
        break;

      case 23:
        return AlertTypes.Danger;
        break;

      default:
        return AlertTypes.Danger;
        break;
    }
  }
}

export interface IBackendError {
  success: boolean;
  messageType: EMessageTypes;
  message: string;
  errorHashId: string;
}

export enum EMessageTypes {
  Primary = 1,
  Secondary = 2,
  Success = 3,
  Danger = 4,
  Warning = 5,
  Info = 6,
  Light = 7,
  Dark = 8,
  DuplicateCode = 20,
  DuplicateTitle = 21,
  DuplicateCodeAndTitle = 22,
  RecordNotFound = 23,
}
