<div class="form-group">
  <label for="{{lookupId}}"
    >{{ label }}: <span *ngIf="requiredField" class="required"> *</span> </label
  >
  <div class="input-group">
    <input
      type="search"
      (search)="handleSearchClear()"
      id="typeahead-http"
      class="form-control date-field"
      [class.is-invalid]="searchFailed || isInvalid"
      [(ngModel)]="model"
      [ngbTypeahead]="search"
      placeholder="{{ placeholder }}"
      (focus)="focus$.next($any($event).target.value)"
      (click)="click$.next($any($event).target.value)"
      #instance="ngbTypeahead"
      [inputFormatter]="inputFormatter"
      [resultFormatter]="resultFormatter"
      [editable]='false'
      (selectItem)="handleSelect($event)"
      [disabled]="isDisabled"
    />
    <!-- <button
      class="btn btn-secondary"
      type="button"
      disabled
    ><fa-icon [icon]="searchIcon"></fa-icon></button> -->
  </div>
    <small *ngIf="searching" class="form-text text-muted">Ieškoma...</small>
    <div class="invalid-feedback" *ngIf="searchFailed">
      Nepavyko rasti rezultatų.
    </div>
</div>
