import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-environment',
  templateUrl: './environment.component.html',
  styleUrls: ['./environment.component.less']
})
export class EnvironmentComponent implements OnInit {

  systemTitle = environment.displayTitle;

  systemVersion = environment.version;

  constructor() { }

  ngOnInit(): void {
  }

}
