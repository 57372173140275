<div class="header-sub header-sub--center">
  <div class="header-sub-left">
    <div class="back">
      <button class="btn btn-dark btn-min160" (click)="onCancelButtonClick($event)">{{cancelButtonTitle}}</button>
    </div>
  </div>
  <div class="header-sub-center">
    <div class="title">
      {{title}}
    </div>
  </div>
  <div class="header-sub-right">
    <div class="action">
      <button [disabled]="isDisabled" class="btn btn-min160" (click)="onSaveButtonClick($event)" *ngIf="isPermitted">{{saveButtonTitle}}</button>
    </div>
  </div>
  <div class="clear"></div>
</div>
