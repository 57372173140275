import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { PageSizeDropdownComponent } from '../page-size-dropdown/page-size-dropdown.component';

@Component({
  selector: 'app-table-paging',
  templateUrl: './table-paging.component.html',
  styleUrls: ['./table-paging.component.less'],
})
export class TablePagingComponent implements OnInit {
  @Input()
  totalCount?: number;

  @Input()
  maxSize?: number;

  @Input()
  maxResultCount?: number;

  @Output()
  pageChange: EventEmitter<number> = new EventEmitter<number>();

  pageValue: number = 0;

  angleLeftIcon = faAngleLeft;
  angleRightIcon = faAngleRight;

  iconSize: SizeProp = 'lg';

  constructor() {}

  ngOnInit(): void {}

  @Input()
  get page() {
    return this.pageValue;
  }

  set page(value: number) {
    this.pageValue = value ;
    this.pageChange.emit(this.pageValue);
  }
}
