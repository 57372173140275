@if(link() == true){
    <a *ngIf="haveRole()" [ngClass]="style()" (click)="clickButton()"
        [attr.aria-disabled]="!haveRole()" tabindex="{{haveRole() ? '0' : '-1'}}">
        {{name()}}
    </a>
}
@else {
    <button [attr.type]="type()" [attr.value]="value()" *ngIf="haveRole()" [ngClass]="style()" (click)="clickButton()">
        {{name()}}
    </button>
}
