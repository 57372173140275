import { Component, OnInit } from '@angular/core';
import { UserAuthorizationService } from 'src/app/modules/user/user-authorization.service';

@Component({
  selector: 'app-primary-menu-header',
  templateUrl: './primary-menu-header.component.html',
  styleUrls: ['./primary-menu-header.component.less']
})
export class PrimaryMenuHeaderComponent implements OnInit {

  mobileMenu: boolean = false;

  constructor(public userAuthService: UserAuthorizationService) { }

  ngOnInit(): void {
  }

  toggleMobileMenu() {
    this.mobileMenu = !this.mobileMenu;
  }
}