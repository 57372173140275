import { environment } from 'src/environments/environment';
import { Classifiers } from './classifiers.enum';

export interface ClsfMap {
  clsfId?: Classifiers;
  apiUrl: string;
  clsfName: string;
  editable: boolean;
}

export const getClsfApiMap = (): ClsfMap[] => {
  var mapper: ClsfMap[] = [
    {
      apiUrl: environment.clsfApi.ClsfActivityType,
      clsfId: Classifiers.ClsfActivityType,
      clsfName: 'Veiklos grupė',
      editable: false,
    },
    {
      apiUrl: environment.clsfApi.ClsfAnimalSpecy,
      clsfId: Classifiers.ClsfAnimalSpecy,
      clsfName: 'Gyvūno rūšis',
      editable: false,
    },
    {
      apiUrl: environment.clsfApi.ClsfAnimalSpecyType,
      clsfId: Classifiers.ClsfAnimalSpecyType,
      clsfName: 'Gyvūno rūšies tipas',
      editable: false,
    },
    {
      apiUrl: environment.clsfApi.ClsfDataSource,
      clsfId: Classifiers.ClsfDataSource,
      clsfName: 'Šaltinis',
      editable: false,
    },
    // {
    //   apiUrl: environment.clsfApi.ClsfFrequencyOfInspection,
    //   clsfId: Classifiers.ClsfFrequencyOfInspection,
    //   clsfName: 'Patikrinimų dažnumas',
    // },
    {
      apiUrl: environment.clsfApi.ClsfInspectionObjective,
      clsfId: Classifiers.ClsfInspectionObjective,
      clsfName: 'Patikrinimo tikslai',
      editable: true,
    },
    {
      apiUrl: environment.clsfApi.ClsfActivity,
      clsfId: Classifiers.ClsfActivity,
      clsfName: 'Veikla',
      editable: false,
    },
    {
      apiUrl: environment.clsfApi.ClsfOrganization,
      clsfId: Classifiers.ClsfOrganization,
      clsfName: 'Skyriai',
      editable: false,
    },
    {
      apiUrl: environment.clsfApi.ClsfSelfControlSystemState,
      clsfId: Classifiers.ClsfSelfControlSystemState,
      clsfName: 'Savikontrolės sistemos būklė',
      editable: true,
    },
    {
      apiUrl: environment.clsfApi.ClsfControlGroup,
      clsfId: Classifiers.ClsfControlGroup,
      clsfName: 'Kontrolės grupė',
      editable: true,
    },
    {
      apiUrl: environment.clsfApi.ClsfInspectionSubtype,
      clsfId: Classifiers.ClsfInspectionSubtype,
      clsfName: 'Pavedimo porūšis',
      editable: true,
    },

    {
      apiUrl: environment.clsfApi.ClsfPurposeOfSampling,
      clsfId: Classifiers.ClsfPurposeOfSampling,
      clsfName: 'Mėginių ėmimo tikslas',
      editable: true,
    },
    {
      apiUrl: environment.clsfApi.ClsfSampleType,
      clsfId: Classifiers.ClsfSampleType,
      clsfName: 'Mėginių tipas',
      editable: true,
    },
    {
      apiUrl: environment.clsfApi.ClsfSupervisionObjective,
      clsfId: Classifiers.ClsfSupervisionObjective,
      clsfName: 'Priežiūros tikslai',
      editable: true,
    },
    {
      apiUrl: environment.clsfApi.ClsfLegislation,
      clsfId: Classifiers.ClsfLegislation,
      clsfName: 'Teisės aktai',
      editable: false,
    },
    {
      apiUrl: environment.clsfApi.ClsfLegislationPart,
      clsfId: Classifiers.ClsfLegislationPart,
      clsfName: 'Teisės akto dalys',
      editable: false,
    },
    {
      apiUrl: environment.clsfApi.ClsfInformationForm,
      clsfId: Classifiers.ClsfInformationForm,
      clsfName: 'Informavimo forma',
      editable: true,
    },
    {
      apiUrl: environment.clsfApi.ClsfReasonForNotApplyingTheSanction,
      clsfId: Classifiers.ClsfReasonForNotApplyingTheSanction,
      clsfName: 'Sankcijos netaikymo priežastys',
      editable: true,
    },
    {
      apiUrl: environment.clsfApi.ClsfSanctionGroup,
      clsfId: Classifiers.ClsfSanctionGroup,
      clsfName: 'Sankcijų grupė',
      editable: true,
    },
    {
      apiUrl: environment.clsfApi.ClsfSanction,
      clsfId: Classifiers.ClsfSanction,
      clsfName: 'Sankcija',
      editable: true,
    },
    // {
    //   apiUrl: environment.clsfApi.ClsfSanctionLegislation,
    //   clsfId: Classifiers.ClsfSanctionLegislation,
    //   clsfName: 'Sankcijų teisės aktai (straipsniai)',
    // },
    {
      apiUrl: environment.clsfApi.ClsfOtherInstitution,
      clsfId: Classifiers.ClsfOtherInstitution,
      clsfName: 'Kita institucija (ne VMVT)',
      editable: true,
    },
    {
      apiUrl: environment.clsfApi.ClsfSamplingLocation,
      clsfId: Classifiers.ClsfSamplingLocation,
      clsfName: 'Mėginio ėmimo vieta',
      editable: true,
    },
    {
      apiUrl: environment.clsfApi.ClsfResearchLaboratory,
      clsfId: Classifiers.ClsfResearchLaboratory,
      clsfName: 'Tyrimų laboratorija',
      editable: true,
    },
    {
      apiUrl: environment.clsfApi.ClsfPayer,
      clsfId: Classifiers.ClsfPayer,
      clsfName: 'Mokėtojas',
      editable: true,
    },
    {
      apiUrl: environment.clsfApi.ClsfUnitOfMeasurement,
      clsfId: Classifiers.ClsfUnitOfMeasurement,
      clsfName: 'Matavimo vienetas',
      editable: true,
    },
    {
      apiUrl: environment.clsfApi.ClsfRequirementGroup,
      clsfId: Classifiers.ClsfRequirementGroup,
      clsfName: 'Reikalavimo pogrupis',
      editable: true,
    },
    {
      apiUrl: environment.clsfApi.ClsfQualitySystemProgram,
      clsfId: Classifiers.ClsfQualitySystemProgram,
      clsfName: 'Kokybės sistemos programa',
      editable: true,
    },
    {
      apiUrl: environment.clsfApi.ClsfQuestionnaire,
      clsfId: Classifiers.ClsfQuestionnaire,
      clsfName: 'Klausimynas',
      editable: false,
    },
    {
      apiUrl: environment.clsfApi.ClsfRequirement,
      clsfId: Classifiers.ClsfRequirement,
      clsfName: 'Kontrolės grupė',
      editable: true,
    },
    {
      apiUrl: environment.clsfApi.ClsfComplaintMethod,
      clsfId: Classifiers.ClsfComplaintMethod,
      clsfName: 'Pranešimo forma',
      editable: true,
    },
    {
      apiUrl: environment.clsfApi.ClsfPosition,
      clsfId: Classifiers.ClsfPosition,
      clsfName: 'Pareigos',
      editable: false,
    },
    {
      apiUrl: environment.clsfApi.ClsfIdentifiedViolation,
      clsfId: Classifiers.ClsfIdentifiedViolation,
      clsfName: 'Nustatyti pažeidimai',
      editable: true,
    },
    {
      apiUrl: environment.clsfApi.ClsfAppliedExposureMeasure,
      clsfId: Classifiers.ClsfAppliedExposureMeasure,
      clsfName: 'Taikytos poveikio priemonės',
      editable: true,
    },
    {
      apiUrl: environment.clsfApi.ClsfRiskFactor,
      clsfId: Classifiers.ClsfRiskFactor,
      clsfName: 'Rizikos veiksniai',
      editable: false,
    },
    {
      apiUrl: environment.clsfApi.ClsfCapacityIndicator,
      clsfId: Classifiers.ClsfCapacityIndicator,
      clsfName: 'Apimties/pajėgumų rodikliai',
      editable: true,
    },
    {
      apiUrl: environment.clsfApi.ClsfEconomicActivityType,
      clsfId: Classifiers.ClsfEconomicActivityType,
      clsfName: 'Ekonominės veiklos rūšys',
      editable: true,
    },
    {
      apiUrl: environment.clsfApi.ClsfMarketIndicator,
      clsfId: Classifiers.ClsfMarketIndicator,
      clsfName: 'Taikomas rinkos kriterijus',
      editable: true,
    },
  ];

  return mapper;
};

export function getClsfMap(clsfType: Classifiers): ClsfMap {
  return getClsfApiMap().find(clsf => clsf.clsfId == clsfType) ?? {} as ClsfMap;
}