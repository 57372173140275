import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const noop = () => {};

export const PAGE_SIZE_DROPDOWN_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PageSizeDropdownComponent),
  multi: true,
};

@Component({
  selector: 'app-page-size-dropdown',
  templateUrl: './page-size-dropdown.component.html',
  styleUrls: ['./page-size-dropdown.component.less'],
  providers: [PAGE_SIZE_DROPDOWN_CONTROL_VALUE_ACCESSOR],
})
export class PageSizeDropdownComponent implements ControlValueAccessor {
  pageSizes: number[] = [1, 5, 10, 25, 50, 100];

  pageSize: number = this.pageSizes[2];

  private onTouchedCallback: () => void = noop;

  private onChangeCallback: (_: any) => void = noop;

  get value(): number {
    return this.pageSize;
  }

  set value(v: number) {
    if (v !== this.pageSize) {
      this.pageSize = v;
      this.onChangeCallback(v);
    }
  }

  onBlur() {
    this.onTouchedCallback();
  }

  writeValue(value: number) {
    if (value !== this.pageSize) {
      this.pageSize = value;
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
}
