import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  faCamera,
  faDownload,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FileDescriptorService } from 'src/app/services/file-descriptor.service';
import { downloadBlob } from '../helpers/file-utils';

@Component({
  selector: '[app-single-file-upload], app-single-file-upload',
  templateUrl: './single-file-upload.component.html',
  styleUrls: ['./single-file-upload.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SingleFileUploadComponent,
      multi: true,
    },
  ],
})
export class SingleFileUploadComponent implements ControlValueAccessor, OnInit {
  @Input()
  existingFileName?: string | undefined;

  @Input()
  existingFileId?: number | undefined;

  @Input()
  acceptAttr: string = 'image/*';

  cameraIcon = faCamera;

  deleteIcon = faTrash;

  downloadIcon = faDownload;

  private onTouched!: Function;

  private onChanged!: Function;

  public file: File | null = null;

  @Input()
  isDisabled = false;

  @Output()
  onRemove = new EventEmitter<number | undefined>();

  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
    const file = event && event.item(0);
    this.onChanged(file);
    this.file = file;
  }

  constructor(
    private host: ElementRef<HTMLInputElement>,
    private fileService: FileDescriptorService
    ) {}

  writeValue(value: null) {
    this.host.nativeElement.value = '';
    this.file = null;
  }

  registerOnChange(fn: Function) {
    this.onChanged = fn;
  }

  registerOnTouched(fn: Function) {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  ngOnInit(): void {}

  onSelectedFileRemove() {
    this.onRemove.emit(this.existingFileId);
    this.host.nativeElement.value = '';
    this.file = null;
    this.existingFileId = undefined;
    this.existingFileName = undefined;
    this.onChanged();
  }

  downloadFile() {
    if (this.existingFileId && this.existingFileName) {
      this.fileService.download(this.existingFileId, this.existingFileName);
    } else if (this.file) {
      downloadBlob(this.file, this.file.name);
    }
  }
}
