import { Component, Input } from '@angular/core';
import { UserAuthorizationService } from 'src/app/modules/user/user-authorization.service';

@Component({
  selector: 'app-third-tabs-menu',
  templateUrl: './third-tabs-menu.component.html',
  styleUrls: ['./third-tabs-menu.component.less'],
})
export class ThirdTabsMenuComponent {
  @Input()
  resourceId: number = 0;

  @Input()
  urlBase: string = '';

  constructor(public authorizationService: UserAuthorizationService) {}
}
