import { Component, input, output } from '@angular/core';

@Component({
  selector: 'app-authorized-button',
  templateUrl: './authorized-button.component.html'
})
export class AuthorizedButtonComponent {
    haveRole = input.required<boolean>();
    name = input.required<string>();
    style = input<string>('btn btn-small btn-min196');
    click = output();
    type = input<string>('button');
    value = input<string>('');
    link = input<boolean>(false);

    clickButton(){
        this.click.emit();
    }
}
