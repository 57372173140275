import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, tap } from 'rxjs/operators';
import { UserPermissions, UserRoles } from '../modules/user/models/user-permissions';
import { BackendErrorHandlerService } from './backend-error-handler.service';
@Injectable({
  providedIn: 'root',
})
export class AuthService {

  isLoggedIn$ = new BehaviorSubject<boolean>(false);
  //currentUserSubject$ = new BehaviorSubject<Lookup | null>(null);
  // currentUser$ = this.http.get<User>(`${environment.api.userUrl}`).pipe(
  //   tap(() => this.isLoggedIn$.next(true)),
  //   catchError((e) => {
  //     this.isLoggedIn$.next(false)
  //     this.errorHandler.renderHandledError(e);
  //     return of({} as User);
  //   })
  // );
   
  userState: User | null = null;
  //activeOrganizationIndex: number | null = null;

  roleHomes = [
    { role: '', home: '/login' }
  ];

  constructor(
    private http: HttpClient,
    private errorHandler: BackendErrorHandlerService
  ) { }

  confirmLogout() {
    this.userState = null;
    this.isLoggedIn$.next(false);
    window.location.href = 'https://vmvt.lrv.lt';
  }
  
  loginRedirect() {
	  window.location.href = environment.api.loginUrl;
  }

  hasPermission(permissions: UserPermissions[], isAllPermissionsRequired: boolean = false): boolean {
    let permissionFound = false;
    permissions.forEach((permission) => {
      if (this.userState?.permissions?.includes(permission)) {
        permissionFound = true;
        return;
      } 
      else if (isAllPermissionsRequired) {
        permissionFound = false;
        return;
      }
    });
    return permissionFound;
  }

  getHomeRoute(): string {
    let homePath = '';
    for (const rh of this.roleHomes) {
      if (this.userState?.roles.includes(rh.role as UserRoles)) {
        homePath = rh.home;
        break;
      }
    }
    return homePath;
  }

  getLogin(): Observable<User> {
    return this.http.get<User>(`${environment.api.userUrl}`).pipe(
      tap((res: User) => {
        this.userState = res;
        //this.activeOrganizationIndex = this.userState.userInfo.clsfOrganizations.find(x => x.isSelected);
        this.isLoggedIn$.next(true);
      }),
      catchError((e) => {
        this.errorHandler.renderHandledError(e);
        return of({} as User);
      })
    );
  }

  logoutUser(): void {
    this.http.get<any>(environment.api.logoutUrl).subscribe({
      next: () => {
        this.confirmLogout();
      },
      error: (e) => {
        this.errorHandler.renderHandledError(e);
      }
    });
  }
}

export interface User {
  id: string;
  login: string;
  displayName: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  department: string;
  email: string;
  phone: string;
  sessionExtend: Date;
	sessionExpire: Date;
  roles: UserRoles[];
	permissions: UserPermissions[];
  organizations: string[];
  userInfo: UserInfoDto;
}
export interface UserInfoDto {
  employeeId: number;
  clsfPositions: UserInfoPositionDto[];
  clsfOrganizations: UserInfoOrganizationDto[];
}

export interface UserInfoPositionDto {
  clsfPositionId: number;
  clsfPositionName: string;
  clsfPositionCode: string;
}
export interface UserInfoOrganizationDto {
  isSelected: boolean;
  clsfOrganizationId: number;
  clsfOrganizationName: string;
  clsfOrganizationCode: string;
}