export enum BackEndErrorMessageEnum {
    ServerIsDown = 'Ryšio klaida, serveris nepasiekiamas!',
    ADServerNotAvailable = 'Ryšio klaida, nėra ryšio su AD!',
    DbConnectionLost = 'Duomenų bazė nepasiekiama.',
    Forbidden = 'Dėmesio, nepakankamas prieigos lygis.',
    ValidationError = 'Dėmesio, bandėte siųsti neteisingus duomenys.',
    Unaithorized = 'Dėmesio, Neteisingi prisijungimo duomenys.',
    UserFirstLoginSuccess = 'Kad galėtumėte naudotis sistema, jūsų prisijungimo duomenis turi patvirtinti administratorius.',
    NotSaved = 'Neišsisaugojo.',
    UnhandledError = 'Nežinoma klaida.',
    Error = 'Klaida.',
    FailedToLoadMedicineMissingId = 'Nepavyko užkrauti vaisto, truksta vaisto identifikatoriaus.',
    PrintoutTypeIsRequired = 'Nenustatytas dokumento tipas!',
}

export enum BackEndWarningMessageEnum {
    OperationNotPossible = 'Operacija nėra įmanoma.',
    SomethingWentWrong = 'Kažkas nepavyko, bandykit dar kartą.',
}

export enum BackEndSuccessMessageEnum {
    OperationDone = 'Įvykdyta.',
    Saved = 'Išsaugota.',
    UserCreated = 'Naudotojas sukurtas!',
    Created = 'Sukurtas!',
    Inserted = 'Pridėta!',
    SuccessFalse = 'Nepavyko!',
    RegistrationCreated = 'Subjektas užregistruotas.',
    MedicineConfirmed = 'Vaistas užregistruotas.',
    Deleted = 'Ištrinta!',
}

export enum BackEndInfoMessageEnum {

}

export enum AlertTypes {
    Primary = 'alert-primary',
    Secondary = 'alert-secondary',
    Success = 'alert-success',
    Danger = 'alert-danger',
    Warning = 'alert-warning',
    Info = 'alert-info',
    Light = 'alert-light',
    Dark = 'alert-dark',
}

export enum MedicineMessages {
    Approved = 'Vaistas užregistruotas.',
    ReRegistered = 'Vaistas perregistruotas.',
    Repealed = 'Vaistas panaikintas.',
    FailedToRepeal = 'Negalima ištrinti vaisto.'
}
