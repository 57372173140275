<div class="table-pagging f-left">
  <ngb-pagination
    class="d-flex justify-content-start"
    [collectionSize]="totalCount || 0"
    [maxSize]="maxSize || 5"
    [pageSize]="maxResultCount || 0"
    [(page)]="page"
    [rotate]="true"
  >
  <ng-template ngbPaginationPrevious><fa-icon [icon]="angleLeftIcon" [size]="iconSize"></fa-icon></ng-template>
  <ng-template ngbPaginationNext><fa-icon [icon]="angleRightIcon" [size]="iconSize"></fa-icon></ng-template>
</ngb-pagination>
</div>
<div class="table-pagging f-right">
  <ngb-pagination
    class="d-flex justify-content-end"
    [collectionSize]="totalCount || 0"
    [maxSize]="maxSize || 5"
    [pageSize]="maxResultCount || 0"
    [(page)]="page"
    [rotate]="true"
  >
  <ng-template ngbPaginationPrevious><fa-icon [icon]="angleLeftIcon" [size]="iconSize"></fa-icon></ng-template>
  <ng-template ngbPaginationNext><fa-icon [icon]="angleRightIcon" [size]="iconSize"></fa-icon></ng-template>
</ngb-pagination>
</div>
