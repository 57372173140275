<div class="pagging">
  <label for="pagging-top"> Puslapio dydis: </label>
  <select
    [(ngModel)]="value"
    name="pagging-top"
    id="pagging-top"
    class="small-select"
    (blur)="onBlur()"
  >
    <option *ngFor="let pageSize of pageSizes" [ngValue]="pageSize">
      {{ pageSize }}
    </option>
  </select>
</div>
