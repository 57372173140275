import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { downloadBlob } from '../shared/helpers/file-utils';

@Injectable({
  providedIn: 'root',
})
export class FileDescriptorService {
  readonly reqOptions = {};

  constructor(protected http: HttpClient) {}

  download(id: number, fileName: string) {
    const apiUrl = `${environment.api.fileDescriptorApiUrl}/download/${id}`;
    this.http
      .get(apiUrl, {
        responseType: 'blob',
      })
      .subscribe((response) =>
        downloadBlob(response, fileName)
      );
  }
}
