<div class="form-group">
  <label *ngIf="label" for="{{ name }}">
    {{ label }}
  </label>
  <div class="input-group">
    <input
      class="form-control date-field"
      placeholder="{{ placeholder }}"
      name="{{ name }}"
      [formControl]="dateControl"
      ngbDatepicker
      #d="ngbDatepicker"
      (blur)="onBlur()"
    />
    <button
      class="btn btn-secondary"
      (click)="d.toggle()"
      type="button"
    ><fa-icon [icon]="calendarIcon"></fa-icon></button>
  </div>
</div>
<div class="text-danger" *ngIf="dateControl.touched && dateControl?.errors?.['ngbDate']?.['invalid']"> Data užpildyta <strong>neteisingai</strong></div>
<div *ngIf="errorMessage" class="text-danger" [innerHTML]="errorMessage"></div>
