import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PagedResultDto } from 'src/app/shared/models/paging-sorting';
import { RestBaseService } from 'src/app/shared/services/rest-base.service';
import { environment } from 'src/environments/environment';
import { OrderListItemDto } from '../../order/order-list/models/order-list-item-dto.model';
import { OrderListFilterDto } from '../../order/order-search/models/order-list-filter-dto.model';

@Injectable({
  providedIn: 'root'
})
export class ControlObjectOrderService extends RestBaseService {
  constructor(http: HttpClient) {
    super(http);
  }

  getFilteredList(id: number, filter: OrderListFilterDto): Observable<PagedResultDto<OrderListItemDto>> {
    return this.GetFilteredAsync<PagedResultDto<OrderListItemDto>>(
      filter,
      `${environment.api.controlObjectOrdersApiUrl}/${id}`
    );
  }
  
}
