import { mapEnumToOptions } from '../helpers/form-utils';
import { Pipe, PipeTransform } from '@angular/core';

// KL-27 - Tikrinimo rūšis
export enum InspectionKind {
  Scheduled = 1,
  Unscheduled = 2,
}

export const inspectionKindOptions = mapEnumToOptions<typeof InspectionKind>(InspectionKind);
export const inspectionKindLabel = (e: InspectionKind): string => {
  switch (e) {
    case InspectionKind.Scheduled:
      return 'Planinis';
    case InspectionKind.Unscheduled:
      return 'Neplaninis';
    default:
      return 'NEŽINOMA';
  }
};

export const inspectionKindOptionsSelect = mapEnumToOptions<typeof InspectionKind>(InspectionKind).map(e => {
  return {
    id: e.key,
    name: inspectionKindLabel(InspectionKind[ e.key as keyof typeof InspectionKind ]),
    code: '',
  };
});

@Pipe({ name: 'inspectionKindLabel' })
export class InspectionKindLabelPipe implements PipeTransform {
  transform(value: InspectionKind | string): string {
    return inspectionKindLabel(
      typeof value === 'string'
      ? InspectionKind[ value as keyof typeof InspectionKind ]
      : value
    );
  }
}
