import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { faCalendar, faCalendarDay } from '@fortawesome/free-solid-svg-icons';
import {
  NgbDateAdapter,
  NgbDateNativeAdapter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';

const noop = () => {};

export const DATE_PICKER_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DatePickerComponent),
  multi: true,
};

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.less'],
  providers: [
    DATE_PICKER_CONTROL_VALUE_ACCESSOR,
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
  ],
})
export class DatePickerComponent implements ControlValueAccessor {
  @Input()
  name: string = 'datepicker';

  @Input()
  placeholder: string = 'yyyy-mm-dd';

  @Input()
  label?: string;

  selectedValue?: NgbDateStruct;

  calendarIcon = faCalendarDay;

  private onTouchedCallback: () => void = noop;

  private onChangeCallback: (_: any) => void = noop;

  constructor() {}

  get value(): NgbDateStruct | undefined {
    return this.selectedValue;
  }

  set value(v: NgbDateStruct | undefined) {
    if (v !== this.selectedValue) {
      this.selectedValue = v;
      this.onChangeCallback(v);
    }
  }

  onBlur() {
    this.onTouchedCallback();
  }

  writeValue(value: NgbDateStruct) {
    if (value !== this.selectedValue) {
      this.selectedValue = value;
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
}
