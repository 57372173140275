import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RestBaseService } from './services/rest-base.service';
import { PageSizeDropdownComponent } from './page-size-dropdown/page-size-dropdown.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ListService } from './services/list.service';
import { TablePagingComponent } from './table-paging/table-paging.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { EventsTableComponent } from './events-table/events-table.component';
import { OrdersListComponent } from './orders-list/orders-list.component';
import { GeneralDataDisplayComponent } from './general-data-display/general-data-display.component';
import { DividerComponent } from './divider/divider.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { CheckboxMultiSelectComponent } from './checkbox-multi-select/checkbox-multi-select.component';
import { DropdownMultiSelectComponent } from './dropdown-multi-select/dropdown-multi-select.component';
import { ConfirmModalComponent } from './confirm/confirm-modal/confirm-modal.component';
import { ConfirmTemplateDirective } from './confirm/confirm-template.directive';
import { ConfirmService } from './confirm/confirm.service';
import { ConfirmState } from './confirm/confirm-state.model';
import { SharedEnumsModule } from './enums/shared-enums.module';
import { SpinnerModule } from './spinner/spinner.module';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { DateTimePickerComponent } from './date-time-picker/date-time-picker.component';
import { SingleFileUploadComponent } from './single-file-upload/single-file-upload.component';
import { MultipleFilesUploadComponent } from './multiple-files-upload/multiple-files-upload.component';
import { DatePickerAsFormControlComponent } from './date-picker-as-form-control/date-picker-as-form-control.component';
import { AuthorizedButtonComponent } from './authorized-button/authorized-button.component';
import { DisableFormDirective } from './directives/disable-form.directive';


@NgModule({
  declarations: [
    PageSizeDropdownComponent,
    TablePagingComponent,
    EventsTableComponent,
    OrdersListComponent,
    GeneralDataDisplayComponent,
    DividerComponent,
    DatePickerComponent,
    CheckboxMultiSelectComponent,
    DropdownMultiSelectComponent,
    ConfirmModalComponent,
    ConfirmTemplateDirective,
    AutocompleteComponent,
    DateTimePickerComponent,
    SingleFileUploadComponent,
    MultipleFilesUploadComponent,
    DatePickerAsFormControlComponent,
    AuthorizedButtonComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    FontAwesomeModule,
    SharedEnumsModule,
    SpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    DisableFormDirective
  ],
  exports: [
    PageSizeDropdownComponent,
    TablePagingComponent,
    EventsTableComponent,
    OrdersListComponent,
    GeneralDataDisplayComponent,
    DividerComponent,
    DatePickerComponent,
    CheckboxMultiSelectComponent,
    DropdownMultiSelectComponent,
    ConfirmModalComponent,
    ConfirmTemplateDirective,
    AutocompleteComponent,
    DateTimePickerComponent,
    SingleFileUploadComponent,
    MultipleFilesUploadComponent,
    DatePickerAsFormControlComponent,
    AuthorizedButtonComponent,
    DatePickerAsFormControlComponent
  ],
  providers: [
    RestBaseService,
    ListService,
    ConfirmService,
    ConfirmState,
    ConfirmTemplateDirective,
  ],
})
export class SharedModule {}
