import { ClsfBaseResponseDto } from "src/app/modules/classifiers/clsf-dto";

export interface ListItemBase {
  name: string;
  id: number;
}

export interface ListItemWithReference {
  reference?: ClsfBaseResponseDto;
  id: number;
  name: string;
}

export const getItemList = (items: ListItemWithReference[]) => {
  if(!items || !items.length) return [];

  const mappedItemsMap = new Map<number, ListItemBase[]>();

  items.forEach((item) => {
    const { reference, name, id } = item;
    if(!reference) return;

    if (!mappedItemsMap.has(reference?.id)) {
        mappedItemsMap.set(reference?.id, []);
    }

    mappedItemsMap.get(reference?.id)!.push({name, id});
  });

  const mappedItemsList =  Array.from(mappedItemsMap, ([itemId, referenceItems]) => {
    const item = items.find(
      (item) => item?.reference?.id === itemId
    )?.reference;

    return {
      item: item?.name || '',
      referencedItems: referenceItems,
    };
  });

  return mappedItemsList;
};