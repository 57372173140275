import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RestBaseService } from 'src/app/shared/services/rest-base.service';
import { getClsfMap } from './classifiers-api-map';
import { Classifiers } from './classifiers.enum';
import { ClsfBaseCreateDto, ClsfBaseFilterDto, ClsfBaseResponseDto } from './clsf-dto';
import { Observable } from 'rxjs';
import { PagedResultDto } from 'src/app/shared/models/paging-sorting';
import { LookupDto, LookupFilterDto } from 'src/app/shared/models/common';

@Injectable({
  providedIn: 'root',
})
export class ClassifiersService<TClsfResponse = ClsfBaseResponseDto, TClsfCreate = ClsfBaseCreateDto, TClsfFilter = ClsfBaseFilterDto>
  extends RestBaseService {

  constructor(http: HttpClient) {
    super(http);
  }

  public get<T = TClsfResponse>(clsfType: Classifiers, id: number): Observable<T> {
    return this.GetByIdAsync<T>(id, `${getClsfMap(clsfType).apiUrl}`);
  }

  public insert(clsfType: Classifiers, clsf: TClsfCreate): Observable<TClsfResponse> {
    return this.CreateAsync<TClsfResponse>(clsf, getClsfMap(clsfType).apiUrl);
  }

  public update(clsfType: Classifiers, clsfId: number, clsf: TClsfCreate): Observable<TClsfResponse> {
    return this.UpdateAsync<TClsfResponse>(clsfId, clsf, getClsfMap(clsfType).apiUrl);
  }

  public delete(clsfType: Classifiers, clsfId: number) {
    return this.DeleteAsync<TClsfResponse>(clsfId, getClsfMap(clsfType).apiUrl);
  }

  public getList(clsfType: Classifiers, filter?: TClsfFilter): Observable<TClsfResponse[]> {
      return this.GetFilteredAsync<TClsfResponse[]>(filter, `${getClsfMap(clsfType).apiUrl}`);
  }

  public getFilteredAndPagedList(clsfType: Classifiers, filter: TClsfFilter): Observable<PagedResultDto<TClsfResponse>> {
    return this.GetFilteredAsync<PagedResultDto<TClsfResponse>>(filter, `${getClsfMap(clsfType).apiUrl}/filteredAndPaged`);
  }

  public getLookup(clsfType: Classifiers, id: number): Observable<LookupDto> {
    return this.GetByIdAsync<LookupDto>(id, `${getClsfMap(clsfType).apiUrl}/lookup`);
  }

  public getLookupList(clsfType: Classifiers, filter: LookupFilterDto = {}): Observable<LookupDto[]> {
    return this.GetFilteredAsync<LookupDto[]>(filter, `${getClsfMap(clsfType).apiUrl}/lookup`);
  }
}