<div class="header-sub-sub">
  <div class="menu">
    <ul>
      <li>
        <a
          [routerLink]="[urlBase, resourceId]"
          [routerLinkActiveOptions]="{exact: true}"
          routerLinkActive="active"
          [title]="'Objektas'"
        >{{'Objektas'}}</a
        >
      </li>
      <li>
        <a
          [routerLink]="[urlBase, resourceId, 'activities']"
          routerLinkActive="active"
          title="Veiklos"
        >Veiklos</a
        >
      </li>
      <li>
        <a
          [routerLink]="[urlBase, resourceId, 'representatives']"
          routerLinkActive="active"
          title="Atstovaujantys asmenys"
        >Atstovaujantys asmenys</a
        >
      </li>
      <li>
        <a
          *ngIf="authorizationService.canViewEventList"
          [routerLink]="[urlBase, resourceId, 'planned-events']"
          routerLinkActive="active"
          title="Planuojami įvykiai"
        >Planuojami įvykiai</a
        >
      </li>
      <!-- hide until fixed -->
      <!-- <li>
        <a
          [routerLink]="[urlBase, resourceId, 'events-to-be-included']"
          routerLinkActive="active"
          title="Įtrauktini įvykiai"
        >Įtrauktini įvykiai</a
        >
      </li> -->
      <li>
        <a
          *ngIf="authorizationService.canViewRelatedOrders"
          [routerLink]="[urlBase, resourceId, 'related-orders']"
          routerLinkActive="active"
          title="Susiję pavedimai"
        >Susiję pavedimai</a
        >
      </li>
      <li>
        <a
          [routerLink]="['/reports', 'create', resourceId]"
          routerLinkActive="active"
          title="Naujas pranešimas"
        >Naujas pranešimas</a
        >
      </li>
    </ul>
  </div>
</div>
