import { Component, ElementRef, EventEmitter, HostListener, input, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NotificationService } from 'src/app/modules/notifications/services/notification.service';
import { FileDescriptorService } from 'src/app/services/file-descriptor.service';
import { FileAttachmentDto } from '../models/file-attachment-dto.model';
import { faCamera, faDownload, faTrash } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-multiple-files-upload',
  templateUrl: './multiple-files-upload.component.html',
  styleUrls: ['./multiple-files-upload.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: MultipleFilesUploadComponent,
      multi: true
    }
  ]
})
export class MultipleFilesUploadComponent implements ControlValueAccessor {

  private onTouched!: Function;

  private onChange!: Function;

  public files: FileAttachmentDto[] = [];

  public type = input.required<"files" | "images">();

  cameraIcon = faCamera;

  deleteIcon = faTrash;

  downloadIcon = faDownload;

  @Output()
  onRemove = new EventEmitter<number>();

  @ViewChild('fileSelectElement')
  private fileSelectElement?: ElementRef<any>;

  @Input()
  isDisabled = false;

  @HostListener('change', ['$event.target.files']) emitFiles( event: FileList ) {
    const newFiles = event && Array.from(event);

    const uniqueNewFiles = newFiles.filter((newFile: File) => {
      return !this.files.some(f => f.attachedFile?.name === newFile.name || f.fileName === newFile.name);
    });

    const uniqueFileAttachments: FileAttachmentDto[] = uniqueNewFiles.map(unf => {
      return {
        attachedFile: unf
      }
    });

    this.onChange(uniqueFileAttachments);

    uniqueFileAttachments.forEach(ufa => this.files.push(ufa));
  }  

  constructor(
    private host: ElementRef<HTMLInputElement>, 
    private fileService: FileDescriptorService,
    private notificationService: NotificationService
    ) { }

  writeValue( value: FileAttachmentDto[] = [] ) {
    this.onSelectedFileRemove();
    if (value.length > 0) {
      this.files = value;
    }
  }

  onSelectedFileRemove() {
    this.host.nativeElement.value = '';
    this.files = [];
  }

  registerOnChange( fn: Function ) {
    this.onChange = fn;
  }

  registerOnTouched( fn: Function ) {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  removeFileFromList(event: Event, i: number) {
    if (this.isDisabled === false) {
      this.onRemove.emit(i);
      event.preventDefault();
      this.files?.splice(i, 1);
      if (this.fileSelectElement && this.fileSelectElement.nativeElement) {
        this.fileSelectElement.nativeElement.value = '';
      }
    }
  }

  download(event: Event, id?: number, fileName?: string) {
    event.preventDefault();
    if (id && fileName)
    this.fileService.download(id, fileName);
  }
}
