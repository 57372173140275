{
  "name": "vks.ui",
  "version": "1.0.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --port 4210",
    "build": "ng build",
    "test": "ng test",
    "build-development": "ng build --configuration=Development",
    "build-testing": "ng build --configuration=Testing",
    "build-production": "ng build --configuration=Production"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.11",
    "@angular/cdk": "^18.1.1",
    "@angular/common": "^18.2.11",
    "@angular/compiler": "^18.2.11",
    "@angular/core": "^18.2.11",
    "@angular/forms": "^18.2.11",
    "@angular/localize": "^18.2.11",
    "@angular/material": "^18.1.1",
    "@angular/platform-browser": "^18.2.11",
    "@angular/platform-browser-dynamic": "^18.2.11",
    "@angular/router": "^18.2.11",
    "@fortawesome/angular-fontawesome": "^0.15.0",
    "@fortawesome/fontawesome-svg-core": "~6.6.0",
    "@fortawesome/free-solid-svg-icons": "^6.6.0",
    "@ng-bootstrap/ng-bootstrap": "^17.0.1",
    "@popperjs/core": "^2.11.2",
    "bootstrap": "^5.3.3",
    "docx": "^9.0.3",
    "guid-typescript": "^1.0.9",
    "md5-typescript": "^1.0.5",
    "moment": "^2.30.1",
    "rxjs": "~7.8.1",
    "save": "^2.9.0",
    "signature_pad": "^5.0.4",
    "tslib": "^2.8.1",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.11",
    "@angular/cli": "^18.2.11",
    "@angular/compiler-cli": "^18.2.11",
    "@types/jasmine": "~5.1.4",
    "@types/node": "^20.17.6",
    "jasmine-core": "~5.1.2",
    "karma": "~6.4.3",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "~5.4.5"
  }
}
