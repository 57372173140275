
import packageJson from '../../package.json';
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export class ApiUrl {
	static mainUrl = 'https://dev.okis.vmvt.lt';
  static baseUri = 'https://dev.okis.vmvt.lt';
  static apiBaseUrl = `${ApiUrl.baseUri}/api`;

  static loginUrl = `${ApiUrl.baseUri}/auth/login?r=/login`;
  static logoutUrl = `${ApiUrl.baseUri}/auth/logout?r=/login`;
  static userUrl = `${ApiUrl.apiBaseUrl}/user`;

  static subjectApiUrl = `${this.apiBaseUrl}/Subject`;
  static controlObjectsApiUrl = `${this.apiBaseUrl}/ControlObject`
  static controlObjectActivityApiUrl = `${this.apiBaseUrl}/ControlObjectActivity`;
  static controlObjectRepresentativeApiUrl = `${this.apiBaseUrl}/ControlObjectRepresentative`;
  static orderApiUrl = `${this.apiBaseUrl}/Order`
  static myOrderApiUrl = `${this.apiBaseUrl}/MyOrder`
  static controlObjectEventsApiUrl = `${this.apiBaseUrl}/ControlObjectEvent`
  static controlObjectOrdersApiUrl = `${this.apiBaseUrl}/ControlObjectOrder`
  static groupEventsApiUrl = `${this.apiBaseUrl}/GroupEvent`
  static groupOrdersApiUrl = `${this.apiBaseUrl}/GroupOrder`
  static inspectionsApiUrl = `${this.apiBaseUrl}/Inspection`
  static inspectionsActStepApiUrl = `${this.apiBaseUrl}/InspectionActStep`
  static inspectionRelevantInfoStep = `${this.apiBaseUrl}/InspectionRelevantInfoStep`;
  static inspectionAdditionalInfoStep = `${this.apiBaseUrl}/InspectionAdditionalInfoStep`
  static inspectionSanctionStep = `${this.apiBaseUrl}/InspectionSanctionStep`
  static inspectionGoalsStep = `${this.apiBaseUrl}/InspectionGoalsStep`
  static inspectionParticipantsStep = `${this.apiBaseUrl}/InspectionParticipantsStep`
  static inspectionQuestionnaireStep = `${this.apiBaseUrl}/InspectionQuestionnaireStep`
  static inspectionSamplesStep = `${this.apiBaseUrl}/InspectionSamplesStep`
  static riskEvaluation = `${this.apiBaseUrl}/RiskEvaluation`
  static cfgRiskEvaluation = `${this.apiBaseUrl}/CfgRiskEvaluation`;
  static fileDescriptorApiUrl = `${this.apiBaseUrl}/FileDescriptor`;
  static inspectionQuestionnaireRequirementResultUrl = `${this.apiBaseUrl}/InspectionQuestionnaireRequirementResult`;
  static inspectionQuestionnaireFileUrl = `${this.apiBaseUrl}/InspectionQuestionnaireFile`;
  static inspectionQuestionnaireRequirementFileUrl = `${this.apiBaseUrl}/InspectionQuestionnaireRequirementResultFile`;
  static cfgPlanYearConfigApiUrl = `${this.apiBaseUrl}/CfgPlanYearConfig`;
  static cfgMonthlyWorkloadApiUrl = `${this.apiBaseUrl}/CfgMonthlyWorkload`;
  static documentApiUrl = `${this.apiBaseUrl}/Documents`;
  static inspectionGeolocationApiUrl = `${this.apiBaseUrl}/InspectionGeolocation`;
  static addressesRegisterApiUrl = `${this.apiBaseUrl}/AddressesRegister`;
  static employeeApiUrl = `${this.apiBaseUrl}/Employee`;
  static activityApiUrl = `${this.apiBaseUrl}/Activity`;
}


export class ClsfApiUrl {
  static ClsfActivityType: string = `${ApiUrl.apiBaseUrl}/ClsfActivityType`
  static ClsfAnimalSpecy: string = `${ApiUrl.apiBaseUrl}/ClsfAnimalSpecy`
  static ClsfAnimalSpecyType: string = `${ApiUrl.apiBaseUrl}/ClsfAnimalSpecyType`;
  static ClsfDataSource: string = `${ApiUrl.apiBaseUrl}/ClsfDataSource`
  //static ClsfFrequencyOfInspection: string = `${ApiUrl.apiBaseUrl}/ClsfFrequencyOfInspection`
  static ClsfInspectionObjective: string = `${ApiUrl.apiBaseUrl}/ClsfInspectionObjective`
  static ClsfActivity: string = `${ApiUrl.apiBaseUrl}/ClsfActivity`
  static ClsfOrganization: string = `${ApiUrl.apiBaseUrl}/ClsfOrganization`
  static ClsfSelfControlSystemState: string = `${ApiUrl.apiBaseUrl}/ClsfSelfControlSystemState`
  static ClsfControlGroup: string = `${ApiUrl.apiBaseUrl}/ClsfControlGroup`;
  static ClsfInspectionSubtype: string = `${ApiUrl.apiBaseUrl}/ClsfInspectionSubtype`
  static ClsfPurposeOfSampling: string = `${ApiUrl.apiBaseUrl}/ClsfPurposeOfSampling`
  static ClsfSampleType: string = `${ApiUrl.apiBaseUrl}/ClsfSampleType`
  static ClsfSupervisionObjective: string = `${ApiUrl.apiBaseUrl}/ClsfSupervisionObjective`
  static ClsfLegislation: string = `${ApiUrl.apiBaseUrl}/ClsfLegislation`
  static ClsfLegislationPart: string = `${ApiUrl.apiBaseUrl}/ClsfLegislationPart`;
  static ClsfInformationForm: string = `${ApiUrl.apiBaseUrl}/ClsfInformationForm`
  static ClsfReasonForNotApplyingTheSanction: string = `${ApiUrl.apiBaseUrl}/ClsfReasonForNotApplyingTheSanction`
  static ClsfSanctionGroup: string = `${ApiUrl.apiBaseUrl}/ClsfSanctionGroup`;
  static ClsfSanction: string = `${ApiUrl.apiBaseUrl}/ClsfSanction`;
  //static ClsfSanctionLegislation: string = `${ApiUrl.apiBaseUrl}/ClsfSanctionLegislation`;
  static ClsfOtherInstitution: string = `${ApiUrl.apiBaseUrl}/ClsfOtherInstitution`;
  static ClsfSamplingLocation: string = `${ApiUrl.apiBaseUrl}/ClsfSamplingLocation`;
  static ClsfResearchLaboratory: string = `${ApiUrl.apiBaseUrl}/ClsfResearchLaboratory`;
  static ClsfPayer: string = `${ApiUrl.apiBaseUrl}/ClsfPayer`;
  static ClsfUnitOfMeasurement: string = `${ApiUrl.apiBaseUrl}/ClsfUnitOfMeasurement`;
  static ClsfRequirementGroup: string = `${ApiUrl.apiBaseUrl}/ClsfRequirementGroup`;
  static ClsfQualitySystemProgram: string = `${ApiUrl.apiBaseUrl}/ClsfQualitySystemProgram`;
  static ClsfQuestionnaire: string = `${ApiUrl.apiBaseUrl}/ClsfQuestionnaire`;
  static ClsfRequirement: string = `${ApiUrl.apiBaseUrl}/ClsfRequirement`;
  static ClsfComplaintMethod: string = `${ApiUrl.apiBaseUrl}/ClsfComplaintMethod`;
  static ClsfPosition: string = `${ApiUrl.apiBaseUrl}/ClsfPosition`;
  static ClsfIdentifiedViolation: string = `${ApiUrl.apiBaseUrl}/ClsfIdentifiedViolation`;
  static ClsfAppliedExposureMeasure: string = `${ApiUrl.apiBaseUrl}/ClsfAppliedExposureMeasure`;
  static ClsfRiskFactor: string = `${ApiUrl.apiBaseUrl}/ClsfRiskFactor`;
  static ClsfCapacityIndicator: string = `${ApiUrl.apiBaseUrl}/ClsfCapacityIndicator`;
  static ClsfEconomicActivityType: string = `${ApiUrl.apiBaseUrl}/ClsfEconomicActivityType`;
  static ClsfMarketIndicator: string = `${ApiUrl.apiBaseUrl}/ClsfMarketIndicator`;
}

export class LookupApiUrl {
  static subjectLookupApiUrl = `${ApiUrl.subjectApiUrl}/lookup`;
  static controlObjectLookupApiUrl = `${ApiUrl.controlObjectsApiUrl}/lookup`
  static clsfActivityApiUrl = `${ClsfApiUrl.ClsfActivity}/lookup`
  static clsfLegislationApiUrl = `${ClsfApiUrl.ClsfLegislation}/lookup`;
  static clsfLegislationPartApiUrl = `${ClsfApiUrl.ClsfLegislationPart}/lookup`;
  static settlementsApiUrl = `${ApiUrl.addressesRegisterApiUrl}/settlements`;
  static addressApiUrl = `${ApiUrl.addressesRegisterApiUrl}/addresses`;
}

export class Settings {
  static allowedExtensions = ['pdf', 'doc', 'docx', 'xlsx', 'xls', 'csv', 'png', 'jpeg', 'jpg'];
  static allowedImageExtensions = ['png', 'jpeg', 'jpg'];
  static maxFileUploadSizeInBytes = 10000000;
  static maxFileAttachmentsCount = 5;
}

export const environment = {
  production: false,
  api: ApiUrl,
  clsfApi: ClsfApiUrl,
  lookupApi: LookupApiUrl,
  displayTitle: 'OKIS DEVELOPMENT',
  version: packageJson.version,
  settings: Settings
};